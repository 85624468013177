import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Navbar from '../Components/Shared/navbar';

const Unsubscribe: React.FC = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [searchParams] = useSearchParams();

    const orderId = searchParams.get('orderId');
    const email = searchParams.get('email');

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    return (
        <div className='bg-[#F8F8F8] h-[100vh]'>
            <Navbar />

            <div className='container mx-auto px-[1rem] lg:px-[1.5rem] py-[2%] mx-w-[1536px]'>
                <div className=''>
                    <p className='text-[32px]'>Unsubscribe from Marketing Emails</p>
                    <p>Order ID: {orderId}</p>
                    <p>Email: {email}</p>
                    <div className='mt-5'>
                        {orderId && email && (
                            <label className='text-[18px]'
                            >
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                    className='mr-3 transform scale-125'
                                />
                                Unsubscribe from marketing emails
                            </label>
                        )}
                    </div>
                    <p className='text-gray-500 text-[14px]'>
                        You cannot unsubscribe from operational emails.
                    </p>
                    {isChecked && (
                        <div className='mt-5'>
                            <span className='text-green-700 font-bold border border-green-600 p-2'>
                                You are unsubscribed from marketing emails.
                            </span>

                        </div>
                    )}
                </div>
            </div>

            {/* <div>
                <p>
                    If you wish to unsubscribe from marketing emails, click the link below:
                    <a href="https://test.stays.instacoins.travel/unsubscribe?orderId=${orderId}&email=${email}">Unsubscribe</a>
                </p>
            </div> */}

        </div>
    );
};

export default Unsubscribe;
