import React from 'react'

import { PaymentProps } from '../../../Interfaces/componentInterfaces';

const PaymentBookingDetails: React.FC<PaymentProps> = ({ order }: { order: any }) => {
    const totalGuest = Number(Number(order?.total_guests_adult || 0) + Number(order?.total_guests_children || 0));
    return (
        <>
            <div className='shadow-md rounded-xl flex flex-col gap-4 bg-[#FFFFFF] p-3 md:p-5'>
                <div className='flex justify-center mb-5'>
                    <div className='flex flex-col gap-5'>
                        <p className='text-[24px] font-medium'>Your booking details</p>
                        {/* <button
                            disabled={!prebookhashExists}
                            className='text-[14px] font-medium'
                        >
                            <span className='border-[#000000] border-b'>
                                {prebookhashExists ? 'Edit booking details' : 'Loading'}
                            </span>
                        </button> */}
                    </div>
                </div>

                <div className='flex justify-around'>
                    <div className='text-center flex flex-col gap-1'>
                        <p>Check-in</p>
                        <div>
                            <p className='text-[14px]'>{order?.checkin_time}</p>
                        </div>
                    </div>
                    <div className='text-center flex flex-col gap-1'>
                        <p>Check-out</p>
                        <div>
                            <p className='text-[14px]'>{order?.checkout_time}</p>
                        </div>
                    </div>
                </div>

                <table>
                    <tbody>
                        <tr className=''>
                            <td className='p-0'>
                                <p>Total length of stay</p>
                                <p className='lg:text-[18px] font-[600]'>{order?.length_of_stay}</p>
                            </td>
                            <td className='p-0'>
                                <p className='text-start'>Total guests</p>
                                <p className='lg:text-[18px] font-[600]'>{totalGuest}</p>
                            </td>
                        </tr>

                        <tr>
                            <td className='p-0'>
                                <p>Room type</p>
                                <p className='lg:text-[18px] font-[600]'>{order?.room_type}</p>
                            </td>
                            <td className='p-0'>
                                <p>No. of rooms</p>
                                <p className='lg:text-[18px] font-[600]'>{order?.no_of_rooms}</p>
                            </td>
                        </tr>

                        <tr>
                            <td className='p-0'>
                                <p>Meal type</p>
                                <p className='lg:text-[18px] font-[600]'>
                                    {order?.board_type
                                        ? order?.board_type.replace(/-/g, ' ').toLowerCase().replace(/^\w/, (c: any) => c.toUpperCase())
                                        : ''}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};
export default PaymentBookingDetails;