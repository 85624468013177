import React from "react";

const Footer: React.FC = () => {
  return (
    <>
      <div className="footer-div hidden md:block w-full">
        <div className="footer w-full xl:container mx-auto px-[1rem] md:px-[1.5rem] py-12">
          <img className="mb-12" src="/stay_home_img/Instacoins Travel Logo.svg" alt="" />
          <div className="flex gap-5">
            <div className="w-full md:w-1/3">
              <ul className="flex flex-col gap-4">
                <li className="font-bold">About</li>
                <li><a href="https://instacoins.travel/about" target="_blank" rel="noreferrer" className="text-black hover:text-[#132344]">About us</a></li>
                <li><a href="https://instacoins.travel/how-it-works" target="_blank" rel="noreferrer" className="text-black hover:text-[#132344]">How <span className="font-bold">
                  Instacoins.travel</span> works</a></li>
                <li><a href="https://airlines.iata.org/news" target="_blank" rel="noreferrer" className="text-black hover:text-[#132344]">News</a></li>
              </ul>
            </div>
            <div className="w-full md:w-1/3">
              <ul className="flex flex-col gap-4">
                <li className="font-bold">Community</li>
                <li><a href="https://www.instagram.com/instacoinsgroup/" target="_blank" rel="noreferrer" className="text-black hover:text-[#132344]">Instagram</a></li>
                <li><a href="https://www.facebook.com/p/Instacoins-Group-100082995535845/" target="_blank" rel="noreferrer" className="text-black hover:text-[#132344]">Facebook</a></li>
                <li><a href="https://x.com/InstacoinsGroup" target="_blank" rel="noreferrer" className="text-black hover:text-[#132344]">X</a></li>
                <li><a href="https://www.tiktok.com/@instacoins.group" target="_blank" rel="noreferrer" className="text-black hover:text-[#132344]">Tik Tok</a></li>
                <li><a href="https://www.youtube.com/@Instacoinsgroupofficial" target="_blank" rel="noreferrer" className="text-black hover:text-[#132344]">You Tube</a></li>
              </ul>
            </div>
            <div className="w-full md:w-1/3">
              <ul className="flex flex-col gap-4">
                <li className="font-bold">Support</li>
                <li><a href="mailto:infor@instacoins.travel" className="text-black hover:text-[#132344]">info@instacoins.travel</a></li>
                <li><a href="https://instacoins.travel/terms-conditions" target="_blank" rel="noreferrer" className="text-black hover:text-[#132344]">Terms and conditions</a></li>
                <li><a href="https://instacoins.travel/terms-conditions#cancellation-terms" target="_blank" rel="noreferrer" className="text-black hover:text-[#132344]">Cancellation Terms</a></li>
                {/* <li><a href="https://instacoins.travel/help-center" target="_blank" rel="noreferrer" className="text-black hover:text-[#132344]">Help Center</a></li> */}
                {/* <li><a href="https://instacoins.travel/frequently-asked-questions" target="_blank" rel="noreferrer" className="text-black hover:text-[#132344]">FAQs</a></li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="w-full h-16 flex bg-[#000F2E]">
          <ul className="footer-down-content flex items-center text-white gap-10 mx-auto w-full xl:container px-[1rem] md:px-[1.5rem]">
            <li><a href="https://instacoins.travel/terms-conditions" target="_blank" rel="noreferrer" className="hover:text-gray-200">Terms and Conditions</a></li>
            <li><a href="https://instacoins.travel/privacy-policy" target="_blank" rel="noreferrer" className="hover:text-gray-200">Privacy</a></li>
            <li><a href="https://instacoins.travel/sitemap.xml" target="_blank" rel="noreferrer" className="hover:text-gray-200">Sitemap</a></li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Footer;


