import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import axiosInstance from "../../Utils/axiosConfig";
import { MoreThanNineRoomsProps } from "../../Interfaces/componentInterfaces";

const MoreThanNineRooms: React.FC<MoreThanNineRoomsProps> = ({
  closeModal,
}) => {
  const [userDetails, setUserDetails] = useState<any>({
    hotelName: "",
    checkIn: Date,
    checkOut: Date,
    hotelCategory: "",
    destination: "",
    location: "",
    roomCount: 1,
    oneNightPrice: null,
    currency: "",
    mealPlan: "RO",
    roomCategory: "",
    bedType: "",
    adultCount: 1,
    childrenCount: 0,
    childrenAge: "",
    citizenship: "",
    visitPurpose: "",
    firstAndLastName: "",
    email: "",
    phoneNumber: "",
    questionOrComment: "",
  });

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [errors, setErrors] = useState<any>({});
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setDateRange(dates);
    setUserDetails((prevDetails: any) => ({
      ...prevDetails,
      checkIn: start,
      checkOut: end,
    }));
  };

  const validateFields = () => {
    let newErrors: any = {};

    for (let field in userDetails) {
      const fieldValue = userDetails[field]?.toString().trim();
      if (
        (!fieldValue || fieldValue === "") &&
        field !== "hotelCategory" &&
        field !== "oneNightPrice" &&
        field !== "childrenCount" &&
        field !== "childrenAge" &&
        field !== "bedType" &&
        field !== "currency" &&
        field !== "roomCategory" &&
        field !== "questionOrComment"
      ) {
        newErrors[field] = "This field is required.";
      } else if (
        typeof fieldValue === "string" &&
        field !== "questionOrComment" &&
        fieldValue.length > 512
      ) {
        newErrors[field] = "Maximum 512 characters allowed.";
      }
    }

    if (userDetails.childrenCount > 0) {
      if (!userDetails.childrenAge) {
        newErrors.childrenAge = "Please enter the age of each child.";
      } else {
        const ageArray = userDetails.childrenAge
          .split(",")
          .map((age: string) => age.trim());
        if (ageArray.length !== userDetails.childrenCount) {
          newErrors.childrenAge = `You must provide ${userDetails.childrenCount} age(s).`;
        } else if (
          !ageArray.every(
            (age: string) =>
              /^\d+$/.test(age) && Number(age) >= 1 && Number(age) <= 17
          )
        ) {
          newErrors.childrenAge = "Each age must be a number between 0 and 17.";
        }
      }
    }

    if (
      userDetails.questionOrComment &&
      userDetails.questionOrComment.trim().length > 2000
    ) {
      newErrors.questionOrComment = "Maximum 2000 characters allowed.";
    }

    if (userDetails.oneNightPrice < 0) {
      newErrors.oneNightPrice = "Cannot accept negative value";
    } else if (userDetails.oneNightPrice > 100000) {
      newErrors.oneNightPrice = "Price must be between 0 and 100000";
    }

    if (userDetails.childrenAge && userDetails.childrenCount === 0) {
      newErrors.childrenAge = "First select children count";
    }

    if (userDetails.firstAndLastName && !/^[A-Za-z]+$/.test(userDetails.firstAndLastName)) {
      newErrors.firstAndLastName = "Only alphabets are allowed";
    }

    if (
      userDetails.email &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userDetails.email.trim())
    ) {
      newErrors.email = "Please enter a valid email address.";
    }

    if (userDetails.phoneNumber) {
      const phoneNumber = parsePhoneNumberFromString(userDetails.phoneNumber);
      if (!phoneNumber || !phoneNumber.isValid()) {
        newErrors.phoneNumber = "Invalid phone number or country code.";
      } else if (!phoneNumber.country) {
        newErrors.phoneNumber = "Country code is required.";
      }
    }

    if (!dateRange[0] || !dateRange[1]) {
      newErrors.checkIn = "Please select a valid date range.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleIncrement = (field: string) => {
    setUserDetails((prevDetails: any) => ({
      ...prevDetails,
      [field]:
        field === "childrenCount"
          ? prevDetails[field] + 1 > 10000
            ? 10000
            : prevDetails[field] + 1
          : prevDetails[field] >= 1
            ? prevDetails[field] + 1 > 10000
              ? 10000
              : prevDetails[field] + 1
            : 1,
    }));
  };

  const handleDecrement = (field: string) => {
    setUserDetails((prevDetails: any) => ({
      ...prevDetails,
      [field]:
        field === "childrenCount"
          ? prevDetails[field] > 0
            ? prevDetails[field] - 1
            : 0
          : prevDetails[field] > 1
            ? prevDetails[field] - 1
            : 1,
    }));
  };

  const handlePhoneNumberChange = (value?: string) => {
    setUserDetails((prevDetails: any) => ({
      ...prevDetails,
      phoneNumber: value,
    }));
  };

  const handleUserDetails = (event: { target: any }) => {
    const { name, value, type } = event.target;
    const formattedValue =
      typeof value === "string"
        ? value.trimStart().replace(/\s+/g, " ")
        : type === "number"
          ? value === ""
            ? null
            : parseFloat(value)
          : value;

    setUserDetails((prevDetails: any) => ({
      ...prevDetails,
      [name]: formattedValue,
    }));
  };

  const handleSendRequest = async () => {
    setButtonLoading(true);
    if (validateFields()) {
      const body = {
        hotelName: userDetails.hotelName,
        checkIn: userDetails.checkIn,
        checkOut: userDetails.checkOut,
        hotelCategory: userDetails.hotelCategory,
        destination: userDetails.destination,
        location: userDetails.location,
        roomCount: userDetails.roomCount,
        oneNightPrice: userDetails.oneNightPrice,
        currency: userDetails.currency,
        mealPlan: userDetails.mealPlan,
        roomCategory: userDetails.roomCategory,
        bedType: userDetails.bedType,
        adultCount: userDetails.adultCount,
        childrenCount: userDetails.childrenCount,
        childrenAge: userDetails.childrenAge,
        citizenship: userDetails.citizenship,
        visitPurpose: userDetails.visitPurpose,
        firstAndLastName: userDetails.firstAndLastName,
        email: userDetails.email,
        phoneNumber: userDetails.phoneNumber,
        questionOrComment: userDetails.questionOrComment,
      };

      try {
        const response = await axiosInstance.post("/specialRequest", body);
        const data = response.data;

        if (response.status === 200) {
          toast.success(
            "Thank you! One of our travel planners will contact you."
          );
          closeModal();
          // setTimeout(() => {
          // }, 1000);
        } else {
          toast.error("Failed to sending request.");
          throw new Error(data.message || "Failed to sending request.");
        }
      } catch (error: any) {
        toast.error("Failed to sending request.");
      } finally {
        setButtonLoading(false);
      }
    } else {
      setButtonLoading(false);
      toast.error("Required field is not filled");
    }
  };

  return (
    <>
      <div className="w-full p-3 md:p-5">
        <p className="text-[18px] sm:text-[20px] font-medium">
          Hotels and period of stay
        </p>
        <div className="mt-5 flex flex-col gap-3 lg:gap-4">
          <div className="flex flex-col lg:flex-row w-full gap-3 lg:gap-4">
            <TextField
              fullWidth
              size="small"
              name="hotelName"
              label="Name of hotel"
              value={userDetails.hotelName}
              onChange={handleUserDetails}
              autoComplete="off"
              error={!!errors.hotelName}
              helperText={errors.hotelName}
              required
            />

            <div className="flex flex-col lg:flex-row gap-3 lg:gap-4 w-full">
              <div className="w-full">
                <DatePicker
                  selectsRange={true}
                  startDate={dateRange[0] || undefined}
                  endDate={dateRange[1] || undefined}
                  onChange={handleDateChange}
                  placeholderText="Select date range"
                  dateFormat="dd-MMM-yyyy"
                  minDate={new Date()}
                  maxDate={
                    new Date(new Date().getTime() + 730 * 24 * 60 * 60 * 1000)
                  }
                  monthsShown={2}
                  isClearable
                  customInput={
                    <TextField
                      size="small"
                      error={!!errors.checkIn}
                      helperText={errors.checkIn || ""}
                      fullWidth
                    />
                  }
                />
              </div>

              <TextField
                fullWidth
                size="small"
                name="hotelCategory"
                label="Preferred hotel category"
                value={userDetails.hotelCategory}
                onChange={handleUserDetails}
                autoComplete="off"
                error={!!errors.hotelCategory}
                helperText={errors.hotelCategory}
              />
            </div>
          </div>

          <div className="flex flex-col lg:flex-row gap-3 lg:gap-4">
            <TextField
              fullWidth
              size="small"
              name="destination"
              label="Destination"
              value={userDetails.destination}
              onChange={handleUserDetails}
              autoComplete="off"
              error={!!errors.destination}
              helperText={errors.destination}
              required
            />

            <TextField
              fullWidth
              size="small"
              name="location"
              label="Preferred location"
              value={userDetails.location}
              onChange={handleUserDetails}
              autoComplete="off"
              error={!!errors.location}
              helperText={errors.location}
              required
            />
          </div>
        </div>

        <div className="flex flex-col lg:flex-row gap-3 lg:gap-4 mt-5">
          <div className="w-full">
            <p className="text-[18px] sm:text-[20px] font-medium">Rooms</p>
            <div className="flex flex-col gap-3 lg:gap-4 mt-4">
              <div className="flex flex-col lg:flex-row w-full gap-3 lg:gap-4 items-end">
                <div className="w-full">
                  <p className="text-center">Number of rooms</p>
                  <div className="flex items-center gap-2 border rounded-md px-2 py-1 w-full justify-between mt-1">
                    <button
                      type="button"
                      className="text-[#FFFFFF] bg-gray-600 px-5 py-1 rounded"
                      onClick={() => handleDecrement("roomCount")}
                    >
                      -
                    </button>
                    <span className="font-medium">{userDetails.roomCount}</span>
                    <button
                      type="button"
                      className="text-[#FFFFFF] bg-blue-600 px-5 py-1 rounded"
                      onClick={() => handleIncrement("roomCount")}
                    >
                      +
                    </button>
                  </div>
                </div>
                <FormControl className="w-full" error={!!errors.bedType}>
                  <Select
                    size="small"
                    name="bedType"
                    value={userDetails.bedType}
                    onChange={handleUserDetails}
                    autoComplete="off"
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select bed type
                    </MenuItem>
                    <MenuItem value="single-bed">Single Bed</MenuItem>
                    <MenuItem value="double-bed">Double Bed</MenuItem>
                    <MenuItem value="full-double-bed">Full Double Bed</MenuItem>
                    <MenuItem value="twin-bed">Twin Bed</MenuItem>
                  </Select>
                  {errors.bedType && (
                    <FormHelperText>{errors.bedType}</FormHelperText>
                  )}
                </FormControl>
              </div>

              <div className="flex flex-col lg:flex-row gap-3 lg:gap-4 w-full">
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  name="oneNightPrice"
                  label="Room price for 1 night"
                  value={userDetails.oneNightPrice}
                  onChange={handleUserDetails}
                  autoComplete="off"
                  error={!!errors.oneNightPrice}
                  helperText={errors.oneNightPrice}
                  inputProps={{ min: 0, max: 100000 }}
                />

                <FormControl className="w-full" error={!!errors.currency}>
                  <Select
                    size="small"
                    name="currency"
                    value={userDetails.currency}
                    onChange={handleUserDetails}
                    autoComplete="off"
                    displayEmpty
                    placeholder="Currency"
                  >
                    <MenuItem value="" disabled>
                      Select currency
                    </MenuItem>
                    <MenuItem value="EUR">EUR</MenuItem>
                    <MenuItem value="USD">USD</MenuItem>
                    <MenuItem value="GBP">GBP</MenuItem>
                  </Select>
                  {errors.currency && (
                    <FormHelperText>{errors.currency}</FormHelperText>
                  )}
                </FormControl>
              </div>

              <TextField
                fullWidth
                size="small"
                name="roomCategory"
                label="Room category"
                value={userDetails.roomCategory}
                onChange={handleUserDetails}
                autoComplete="off"
                error={!!errors.roomCategory}
                helperText={errors.roomCategory}
              />
            </div>
          </div>

          <div className="w-full">
            <p className="text-[18px] sm:text-[20px] font-medium">Meals</p>
            <div className="mt-4">
              {[
                { value: "RO", label: "RO - No Meals" },
                { value: "BB", label: "BB - Breakfast" },
                { value: "HB", label: "HB - Half Board" },
                { value: "FB", label: "FB - Full Board" },
                { value: "AI", label: "AI - All-Inclusive" },
              ].map(({ value, label }) => (
                <label key={value} className="text-[18px] flex items-center gap-3 mb-2 cursor-pointer">
                  <input
                    type="radio"
                    name="mealPlan"
                    value={value}
                    checked={userDetails.mealPlan === value}
                    onChange={handleUserDetails}
                    className="transform scale-125 cursor-pointer"
                  />
                  {label}
                </label>
              ))}
            </div>
          </div>
        </div>

        <div className="mt-5">
          <p className="text-[18px] sm:text-[20px] font-medium">Guests</p>
          <div className="mt-4 flex flex-col gap-3 lg:gap-4">
            <div className="flex flex-col lg:flex-row w-full gap-3 lg:gap-4">
              <div className="flex flex-col lg:flex-row w-full gap-3 lg:gap-4">
                {/* Adult Count */}
                <div className="w-full">
                  <p className="text-center">Adults</p>
                  <div className="flex items-center gap-2 border rounded-md px-2 py-1 w-full justify-between mt-1">
                    <button
                      type="button"
                      className="text-[#FFFFFF] bg-gray-600 px-5 py-1 rounded"
                      onClick={() => handleDecrement("adultCount")}
                    >
                      -
                    </button>
                    <span className="font-medium">
                      {userDetails.adultCount}
                    </span>
                    <button
                      type="button"
                      className="text-white bg-blue-600 px-5 py-1 rounded"
                      onClick={() => handleIncrement("adultCount")}
                    >
                      +
                    </button>
                  </div>
                </div>

                {/* Children Count */}
                <div className="w-full">
                  <p className="text-center">Children</p>
                  <div className="flex items-center gap-2 border rounded-md px-2 py-1 w-full justify-between mt-1">
                    <button
                      type="button"
                      className="text-[#FFFFFF] bg-gray-600 px-5 py-1 rounded"
                      onClick={() => handleDecrement("childrenCount")}
                    >
                      -
                    </button>
                    <span className="font-medium">
                      {userDetails.childrenCount}
                    </span>
                    <button
                      type="button"
                      className="text-[#FFFFFF] bg-blue-600 px-5 py-1 rounded"
                      onClick={() => handleIncrement("childrenCount")}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>

              <div className="w-full">
                <p className="mb-1">Age of each child (separated by commas)</p>
                <TextField
                  fullWidth
                  size="small"
                  name="childrenAge"
                  label="Age of children"
                  value={userDetails.childrenAge}
                  onChange={handleUserDetails}
                  autoComplete="off"
                  error={!!errors.childrenAge}
                  helperText={errors.childrenAge}
                  required
                />
              </div>
            </div>

            <div className="flex flex-col lg:flex-row w-full gap-3 lg:gap-4">
              <TextField
                fullWidth
                size="small"
                name="citizenship"
                label="Guests Citizenship"
                value={userDetails.citizenship}
                onChange={handleUserDetails}
                autoComplete="off"
                error={!!errors.citizenship}
                helperText={errors.citizenship}
                required
              />

              <FormControl className="w-full" error={!!errors.visitPurpose}>
                <Select
                  size="small"
                  name="visitPurpose"
                  value={userDetails.visitPurpose}
                  onChange={handleUserDetails}
                  autoComplete="off"
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select purpose of visit
                  </MenuItem>
                  <MenuItem value="Business">Business</MenuItem>
                  <MenuItem value="Travel">Travel</MenuItem>
                </Select>
                {errors.visitPurpose && (
                  <FormHelperText>{errors.visitPurpose}</FormHelperText>
                )}
              </FormControl>
            </div>
          </div>
        </div>

        <div className="w-full mt-5">
          <p className="text-[18px] sm:text-[20px] font-medium">
            Contact information
          </p>
          <div className="flex flex-col gap-3 lg:gap-4 mt-4">
            <div className="flex flex-col lg:flex-row lg:items-start w-full gap-3 lg:gap-4">
              <TextField
                fullWidth
                size="small"
                name="firstAndLastName"
                label="First name and Last name"
                value={userDetails.firstAndLastName}
                onChange={handleUserDetails}
                autoComplete="off"
                error={!!errors.firstAndLastName}
                helperText={errors.firstAndLastName}
                required
              />

              <TextField
                fullWidth
                size="small"
                name="email"
                type="email"
                label="E-mail address"
                value={userDetails.email}
                onChange={handleUserDetails}
                autoComplete="off"
                error={!!errors.email}
                helperText={errors.email}
                required
              />

              <div className="w-full flex flex-col">
                <PhoneInput
                  international
                  value={userDetails.phoneNumber}
                  onChange={handlePhoneNumberChange}
                  className={`border rounded-[4px] w-full ${errors.phoneNumber
                    ? "border-red-700 p-1.5"
                    : "border-[#c4c4c4] p-2"
                    }`}
                  placeholder="Enter phone number"
                />
                {errors.phoneNumber && (
                  <div className="text-[#d32f2f] text-[13px] pt-1">
                    {errors.phoneNumber}
                  </div>
                )}
              </div>
            </div>

            <TextField
              fullWidth
              size="small"
              name="questionOrComment"
              label="Question or Comment (optional)"
              value={userDetails.questionOrComment}
              onChange={handleUserDetails}
              autoComplete="off"
              error={!!errors.questionOrComment}
              helperText={errors.questionOrComment}
            />
          </div>
        </div>
      </div>

      <div className="flex w-full justify-center sticky bottom-0 !z-40 p-3 bg-blue-200">
        <button
          type="button"
          onClick={handleSendRequest}
          className="bg-[#002E8A] hover:bg-[#4826a3] font-medium text-[#FFFFFF] px-5 py-3 rounded-lg w-full lg:w-auto"
        >
          {buttonLoading ? (
            <div className="flex items-center gap-2">
              <span>Sending</span>{" "}
              <CircularProgress size={18} color="inherit" />
            </div>
          ) : (
            <span>Send request</span>
          )}
        </button>
      </div>
    </>
  );
};

export default MoreThanNineRooms;
