import React, { useEffect, useState } from 'react';
import { TextField, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';

import { RootState, AppDispatch } from '../../../Redux/store';
import { setSelectedCouponId, validateCoupon, validateAndApplyCoupon, resetCouponState } from '../../../Redux/Slices/couponSlice';
import CouponApplySkeleton from '../../Skeleton/couponApplySkeleton';
import { CouponSummaryProps } from '../../../Interfaces/componentInterfaces';

const Coupon: React.FC<CouponSummaryProps> = ({ prebookStatus, buttonLoading }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { selectedCouponId, couponError, couponLoading } = useSelector((state: RootState) => state.coupon);
    const [isApplied, setIsApplied] = useState(false);
    useEffect(() => {
        return () => {
            dispatch(resetCouponState());
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(validateCoupon());
    }, [dispatch, selectedCouponId]);

    const handleApplyCoupon = async () => {
        try {
            if (!couponError && selectedCouponId.trim()) {
                dispatch(validateAndApplyCoupon(selectedCouponId))
                    .unwrap()
                    .then(() => {
                        toast.success("Coupon applied successfully!");
                        setIsApplied(true);
                    })
                    .catch((err: any) => {
                        toast.error('This coupon can not be used.');
                        setIsApplied(false);
                    });
            } else {
                toast.error('Failed to validate the coupon.');
                setIsApplied(false);
            }
        } catch (err) {
            toast.error('Failed to validate the coupon.');
            setIsApplied(false);
        } finally {
            setIsApplied(false);
        }
    };

    return (
        <>
            {prebookStatus === "success" ? (
                <div className="shadow-md rounded-xl bg-[#FFFFFF] p-3 lg:p-5 mt-5 flex flex-col gap-2">
                    <p className="text-black font-bold">Coupon apply here <span className='text-green-800'>{isApplied ? "(Applied)" : null}</span></p>
                    <div className="flex gap-3 mt-2 items-start">
                        <TextField
                            className="rounded-md"
                            fullWidth
                            size="small"
                            name="couponId"
                            label="Coupon code"
                            value={selectedCouponId}
                            onChange={(e) => dispatch(setSelectedCouponId(e.target.value.trim()))}
                            error={Boolean(couponError)}
                            helperText={couponError}
                            autoComplete="off"
                        />
                        <button
                            className={`px-3 py-2 rounded-md bg-[#002E8A] text-[#FFFFFF] min-w-[80px] ${couponLoading || buttonLoading || selectedCouponId.length === 0 ? "opacity-50" : "opacity-100"}`}
                            onClick={handleApplyCoupon}
                            disabled={couponLoading || buttonLoading || selectedCouponId.length === 0}
                        >
                            {couponLoading ? (
                                <CircularProgress size={18} color="inherit" />
                            ) : (
                                <span>Apply</span>
                            )}
                        </button>
                    </div>
                </div>
            ) : (
                <CouponApplySkeleton />
            )}
        </>
    );
};

export default Coupon;
