import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, differenceInDays, isBefore } from "date-fns";
import {
  Box,
  Modal,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { addDays } from "date-fns";

import axiosInstance from "../../Utils/axiosConfig";
import { AppDispatch } from "../../Redux/store";
import { setSearchParams } from "../../Redux/Slices/searchVarSlice";
import { country } from "../../Data/country";
import MoreThanNineRooms from "../ModalViews/moreThanNineRooms";
import UserSelection from "../ModalViews/userSelection";
import RegionHotelAutocomplete from "../AutocompleteViews/regionHotelAutocomplete";
import CountryAutocomplete from "../AutocompleteViews/countryAutocomplete";
import { Option, Room, SearchStayProps } from "../../Interfaces/componentInterfaces";

const SearchStay: React.FC<SearchStayProps> = ({
  initialInputValue,
  initialSelectedOption,
  initialStartDate,
  initialEndDate,
  initialRooms,
  initialPage,
  initialRegionId,
  residency,
  isoCodeAlpha2,
}) => {
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [inputValueCountry, setInputValueCountry] = useState<string>("");
  const [countrySelect, setCountrySelect] = useState<string>(residency ?? "");
  const [isoCode, setIsoCode] = useState<string>(isoCodeAlpha2 ?? "");
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option>(initialSelectedOption);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openMoreThanNineRoomsModal, setOpenMoreThanNineRoomsModal] =
    useState(false);
  const [openCountrySelectModal, setOpenCountrySelectModal] = useState(false);

  const isFirstRender = useRef(true);

  const today = new Date();
  const [startDate, setStartDate] = useState<Date | undefined>(
    initialStartDate && !isBefore(initialStartDate, today) ? initialStartDate : today
  );
  const [endDate, setEndDate] = useState<Date | undefined>(() => {
    const calculatedEndDate = initialEndDate || addDays(startDate || today, 7);
    return differenceInDays(calculatedEndDate, startDate || today) > 30
      ? addDays(startDate || today, 30)
      : calculatedEndDate;
  });

  const defaultRooms: Room[] = [{ rooms: 1, adults: 2, children: [] }];
  const [rooms, setRooms] = useState<Room[]>(
    Array.isArray(initialRooms) && initialRooms.length > 0 ? initialRooms : defaultRooms
  );

  const page = initialPage || 0;
  const [regionId, setRegionId] = useState<string>(initialRegionId || "");

  const [checkinDate, setCheckinDate] = useState<string>("");
  const [checkoutDate, setCheckoutDate] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const dateFormat = "dd MMM yyyy";

  const handleOpenUser = () => setOpenUserModal(true);
  const handleCloseUser = () => setOpenUserModal(false);
  const handleCountrySelect = () => setOpenCountrySelectModal(true);
  const handleCloseCountrySelect = () => setOpenCountrySelectModal(false);
  const handleOpenMoreThanNineRooms = () => setOpenMoreThanNineRoomsModal(true);
  const handleCloseMoreThanNineRooms = () =>
    setOpenMoreThanNineRoomsModal(false);

  const handleCloseUserModal = () => {
    setOpenUserModal(false);
  };

  const handleSetChildCount = (index: number, count: number) => {
    const updatedRooms = rooms.map((room, i) => {
      if (i === index) {
        const children = room.children.slice(0, count);
        while (children.length < count) {
          children.push(1);
        }
        return { ...room, children };
      }
      return room;
    });
    setRooms(updatedRooms);
  };

  const handleSetChildAge = (
    index: number,
    childIndex: number,
    age: number
  ) => {
    const updatedRooms = rooms.map((room, i) => {
      if (i === index) {
        const children = room.children.map((child, j) => {
          if (j === childIndex) {
            return age;
          }
          return child;
        });
        return { ...room, children };
      }
      return room;
    });
    setRooms(updatedRooms);
  };

  const handleSetAdultCount = (index: number, count: number) => {
    const updatedRooms = rooms.map((room, i) =>
      i === index ? { ...room, adults: count } : room
    );
    setRooms(updatedRooms);
  };

  const addRoom = () => {
    if (rooms.length < 10) {
      setRooms([...rooms, { rooms: 1, adults: 2, children: [] }]);
    }
  };

  const removeRoom = (index: number) => {
    setRooms(rooms.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (inputValue === initialInputValue) return;

    if (inputValue && inputValue.length > 3) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await axiosInstance.post(
            `/autocomplete`,
            {
              query: inputValue,
              language: "en",
            }
          );
          const json = response.data;
          if (json.data) {
            setOptions(
              [...json.data.hotels, ...json.data.regions].map((item: any) => ({
                id: item.id,
                hid: item.hid || 0,
                name: item.name,
                designation: item.type || "",
                group: item.type ? "Regions" : "Hotels",
                countryCode: item.country_code
                  ? item.country_code
                  : item.region_details?.country_code,
              }))
            );
          } else {
            setOptions([]);
          }
        } catch (error) {
          console.log("Failed to fetch autocomplete data:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    } else {
      setOptions([]);
    }
  }, [inputValue, initialInputValue]);

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    newInputValue: string
  ) => {
    const formattedValue = newInputValue
      .trimStart()
      .replace(/\s+/g, " ");

    setInputValue(formattedValue);
  };

  const handleOptionChange = (
    event: React.ChangeEvent<{}>,
    value: string | Option | null
  ) => {
    if (typeof value === "string") {
      setRegionId(value);
      setSelectedOption({
        id: "",
        hid: 0,
        name: "",
        designation: "",
        group: "",
        countryCode: "",
      });
    } else if (value !== null) {
      setRegionId(value.id);
      setSelectedOption(value);
    } else {
      setRegionId("");
      setSelectedOption({
        id: "",
        hid: 0,
        name: "",
        designation: "",
        group: "",
        countryCode: "",
      });
    }
  };

  const handleCountryChange = (event: React.SyntheticEvent, value: string) => {
    setInputValueCountry(value);
  };

  const handleCountryOptionChange = (event: any, newValue: any) => {
    if (newValue) {
      setCountrySelect(newValue?.name);
      setIsoCode(newValue?.code);
    } else {
      setCountrySelect("");
      setIsoCode("");
    }
  };

  const setDateRange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    if (start && end) {
      const diffInDays =
        (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);

      if (diffInDays > 30) {
        const adjustedEndDate = new Date(
          start.getTime() + 30 * 24 * 60 * 60 * 1000
        );
        setStartDate(start);
        setEndDate(adjustedEndDate);
      } else {
        setStartDate(start);
        setEndDate(end);
      }
    } else {
      setStartDate(start ?? undefined);
      setEndDate(end ?? undefined);
    }
    if (end) {
      setOpenDatePicker(false);
    }
  };

  const datePickerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target as Node)
      ) {
        setOpenDatePicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dayClassName = (date: Date) => {
    if (startDate && !endDate) {
      const diffInDays =
        (date.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
      if (diffInDays > 30) {
        return "disabled-day";
      }
    }
    return "";
  };

  useEffect(() => {
    if (startDate) {
      setCheckinDate(format(startDate, "yyyy-MM-dd"));
    } else {
      setCheckinDate("N/A");
    }

    if (endDate) {
      setCheckoutDate(format(endDate, "yyyy-MM-dd"));
    } else {
      setCheckoutDate("N/A");
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (checkinDate && checkoutDate) {
      const validCheckinDate = !isNaN(new Date(checkinDate).getTime())
        ? checkinDate
        : null;
      const validCheckoutDate = !isNaN(new Date(checkoutDate).getTime())
        ? checkoutDate
        : null;
      dispatch(
        setSearchParams({
          startDate: validCheckinDate,
          endDate: validCheckoutDate,
          roomDetails: rooms,
          id: selectedOption ? selectedOption.id : null,
          hid: selectedOption ? selectedOption.hid : null,
          name: selectedOption ? selectedOption.name : null,
          designation: selectedOption ? selectedOption.designation : null,
          group: selectedOption ? selectedOption.group : null,
          countryCode: selectedOption ? selectedOption.countryCode : null,
          residency: countrySelect,
          isoCodeAlpha2: isoCode,
          inputSearch: selectedOption ? `${selectedOption.name || "Unknown"}${selectedOption.designation ? ` ${selectedOption.designation}` : ""}${selectedOption.countryCode ? `-(${selectedOption.countryCode})` : ""}` : ""
        })
      );
    }
  }, [
    dispatch,
    countrySelect,
    checkinDate,
    checkoutDate,
    rooms,
    selectedOption,
    isoCode,
  ]);

  const handleSearch = async () => {
    if (checkinDate === "N/A" || checkoutDate === "N/A" || !selectedOption || !rooms[0]) {
      console.error("Missing required parameters");
      return;
    }

    if (selectedOption.group === "Hotels") {
      setLoading(true);
      try {
        handleHotelClick(selectedOption.hid);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else if (selectedOption.group === "Regions") {
      setLoading(true);
      try {
        const queryParams = new URLSearchParams({
          page: page.toString(),
          region_id: regionId,
          startDate: checkinDate,
          endDate: checkoutDate,
          roomDetails: JSON.stringify(rooms),
          showAll: "true",
          residency: isoCode ? isoCode : "mt",
        }).toString();

        navigate(`/hotelsByRegion?${queryParams}`, {
          state: {
            selectedOption,
            inputValue: inputValue,
            startDate: checkinDate,
            endDate: checkoutDate,
            roomDetails: rooms,
          },
        });
      } catch (error) {
        console.error("Failed to fetch search data:", error);
      } finally {
        setLoading(false);
        setOpenCountrySelectModal(false);
      }
    }
  };

  const handleHotelClick = (hId: any) => {
    const params = new URLSearchParams({
      hid: hId,
      checkin: checkinDate,
      checkout: checkoutDate,
      guests: JSON.stringify(rooms),
      residency: isoCode ? isoCode : "mt",
    });
    navigate(`/hotels?${params.toString()}`);
  };

  const getTooltipTitle = () => {
    if (!inputValue || !selectedOption.id || !selectedOption.name) {
      return "Select any region or hotel";
    } else if (!startDate || !endDate) {
      return "Select start date and end date";
    } else if (!rooms[0]?.rooms && !rooms[0]?.adults) {
      return "Select room details";
    } else if (differenceInDays(endDate, startDate) > 30) {
      return "The date range cannot exceed 30 days";
    } else {
      return "";
    }
  };
  const isButtonDisabled =
    !inputValue ||
    !selectedOption.id ||
    !selectedOption.name ||
    !startDate ||
    !endDate ||
    differenceInDays(endDate, startDate) > 30 ||
    !(rooms[0]?.rooms && rooms[0]?.adults);

  const style = {
    position: "absolute",
    top: "54%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "auto",
    borderRadius: "8px",
  };

  return (
    <>
      <div id="dateSelecter" className="w-full">
        <div className="stay-top-buttons-div flex w-full">
          <div className="stay-top-button-grp gap-2 bg-[#F3F4F6] overflow-hidden w-full lg:w-auto">
            <a
              href="https://test.instacoins.travel"
              className="stay-top-button w-full justify-center hover:bg-[#FFFFFF] cursor-pointer hover:border-b-2 hover:border-blue-900 transition duration-300 ease-in-out"
            >
              <img
                className="w-[20px] h-[20px] lg:w-[24px] lg:h-[24px]"
                src="/stay_home_img/flight_icon.png"
                alt=""
              />
              <p>Flights</p>
            </a>
            <div className="stay-top-button w-full selecteds bg-white justify-center hover:bg-[#FFFFFF] cursor-pointer hover:border-b-2 hover:border-blue-900 transition duration-300 ease-in-out">
              <img
                className="w-[20px] h-[20px] lg:w-[24px] lg:h-[24px]"
                src="/stay_home_img/stay_icon.png"
                alt=""
              />
              <p>Stays</p>
            </div>
          </div>
        </div>
        <div className="flex custom-lg:grid custom-lg:grid-cols-[83%_15%] gap-5 lg:gap-3 custom-lg:gap-5 w-full custom-lg:items-center shadow pt-7 pb-3 px-3 flex-col custom-lg:flex-row bg-[#FFFFFF] rounded-bl-lg rounded-br-lg rounded-tr-none lg:rounded-tr-lg lg:px-7 lg:py-10">
          <div className="flex w-full border-gray-100 flex-col lg:justify-between gap-2 lg:flex-row lg:border lg:border-[#D1D5DB] lg:rounded-lg lg:items-center">
            {/* Hotel / region selection box with flag */}
            <div className="flex w-full items-center border border-gray-300 gap-2 rounded-md lg:border-none">
              <RegionHotelAutocomplete
                options={options}
                inputValue={inputValue}
                selectedOption={selectedOption}
                countrySelect={countrySelect}
                handleInputChange={handleInputChange}
                handleOptionChange={handleOptionChange}
              />
              <div className="w-[1px] h-[25px] bg-black hidden lg:block"></div>
            </div>

            {/* Date selection button */}
            <div className="flex items-center w-full gap-2 flex-col lg:flex-row relative">
              <div
                className="flex items-center gap-2 px-2 w-full border border-gray-300 rounded-lg lg:border-none relative"
                ref={datePickerRef}
              >
                <img
                  className="w-[16px] h-[16px] lg:w-[24px] lg:h-[24px]"
                  src="/stay_home_img/calendar.png"
                  alt=""
                />
                <button
                  className="min-w-[235px] w-full flex py-3"
                  onClick={() => setOpenDatePicker(!openDatePicker)}
                >
                  {startDate && endDate ? (
                    <p>
                      {format(startDate, dateFormat)} -{" "}
                      {format(endDate, dateFormat)}
                    </p>
                  ) : (
                    <p className="text-gray-400">Select Date</p>
                  )}
                </button>

                {/* start and end date selection with datepicker */}
                {openDatePicker && (
                  <div className="absolute left-0 top-full mt-1 z-40 shadow-xl rounded-lg bg-white border border-gray-300">
                    <div className="flex justify-between items-center w-full py-2 px-4 border-gray-300 border-b">
                      <div className="w-full">
                        {startDate && endDate ? (
                          <p>
                            {format(startDate, dateFormat)} -{" "}
                            {format(endDate, dateFormat)}
                          </p>
                        ) : (
                          "Select Date (Max 30 Nights)"
                        )}
                      </div>
                      <div className="border border-gray-300 rounded-lg w-[30px] h-[30px] flex justify-center">
                        <button
                          onClick={() => setOpenDatePicker(false)}
                          aria-label="close"
                          className="focus:outline-none"
                        >
                          <CloseIcon className="text-gray-500" />
                        </button>
                      </div>
                    </div>
                    <DatePicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update: [Date | null, Date | null]) => {
                        setDateRange(update);
                      }}
                      minDate={new Date()}
                      maxDate={
                        new Date(
                          new Date().getTime() + 730 * 24 * 60 * 60 * 1000
                        )
                      }
                      inline
                      monthsShown={2}
                      dayClassName={dayClassName}
                    />
                  </div>
                )}
              </div>

              <div className="w-[1px] h-[25px] bg-black hidden lg:block"></div>

              {/* User selection button */}
              <div className="flex items-center gap-4 w-full px-3 border border-gray-300 rounded-lg lg:border-none overflow-hidden">
                <button
                  className="min-w-[225px] w-full flex py-3"
                  onClick={handleOpenUser}
                >
                  {rooms.length && rooms[0].adults ? (
                    <p className="m-0 p-0 text-black">
                      {rooms.reduce((total, room) => total + room.rooms, 0)}{" "}
                      Room,{" "}
                      {rooms.reduce((total, room) => total + room.adults, 0)}{" "}
                      Adults,{" "}
                      {rooms.reduce(
                        (total, room) => total + room.children.length,
                        0
                      )}{" "}
                      Children
                    </p>
                  ) : (
                    <p className="text-gray-400">Select User</p>
                  )}
                </button>

                {/* User selection modal */}
                <Modal
                  className="overflow-y-auto !z-40"
                  open={openUserModal}
                  onClose={handleCloseUser}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} className="max-h-[85%]">
                    <div className="flex flex-col gap-5 min-w-[330px] sm:min-w-[400px] relative">
                      <div className="w-full sticky top-0 bg-white !z-40">
                        <div className="flex justify-between items-center px-3 py-2">
                          <p className="font-medium text-[18px]">
                            Maximum 9 rooms selection
                          </p>
                          <IconButton
                            onClick={handleCloseUserModal}
                            aria-label="close"
                            className="!p-0"
                          >
                            <CloseIcon className="border border-[#D1D5DB] rounded-md" />
                          </IconButton>
                        </div>
                        <div className="bg-gray-300 h-[1px] w-full"></div>
                      </div>
                      {rooms.map((room, index) => (
                        <UserSelection
                          key={index}
                          index={index}
                          room={room}
                          removeRoom={() => removeRoom(index)}
                          handleSetAdultCount={(count) => handleSetAdultCount(index, count)}
                          handleSetChildCount={(count) => handleSetChildCount(index, count)}
                          handleSetChildAge={(childIndex, age) => handleSetChildAge(index, childIndex, age)}
                        />
                      ))}

                      {/* Add +1 room / more than 9 rooms selection button */}
                      <div className="sticky bottom-0 bg-[#FFFFFF]">
                        <div className="bg-gray-200 h-[1px] w-full"></div>
                        <div className="flex gap-3 w-full p-3">
                          <Tooltip
                            title={
                              rooms.length >= 9
                                ? "You can select a maximum of 9 rooms"
                                : ""
                            }
                            placement="top"
                            arrow
                          >
                            <button
                              className={`w-full flex border border-[#D1D5DB] px-3 py-2 rounded-md justify-center ${rooms.length >= 9
                                ? "shadow-inner bg-slate-100"
                                : "shadow-md bg-[#FFFFFF]"
                                }`}
                              onClick={addRoom}
                              disabled={rooms.length >= 9}
                            >
                              +1 Room
                            </button>
                          </Tooltip>

                          <Tooltip
                            title={"You can select more than 9 rooms"}
                            placement="top"
                            arrow
                          >
                            <button
                              onClick={handleOpenMoreThanNineRooms}
                              className="w-full flex border border-[#D1D5DB] px-3 py-2 rounded-md justify-center shadow-md bg-[#FFFFFF]"
                            >
                              9 Rooms +
                            </button>
                          </Tooltip>

                          {/* More than 9 rooms selection modal*/}
                          <Modal
                            className="overflow-y-auto !z-40"
                            open={openMoreThanNineRoomsModal}
                            onClose={handleCloseMoreThanNineRooms}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box
                              sx={style}
                              className="max-h-[85%] w-[88%] max-w-[1536px]"
                            >
                              <div className="w-full">
                                <div className="w-full sticky top-0 bg-white !z-40">
                                  <div className="flex justify-between items-center px-3 py-2">
                                    <p className="font-medium text-[18px] sm:text-[24px]">
                                      More than 9 rooms selection
                                    </p>
                                    <IconButton
                                      onClick={handleCloseMoreThanNineRooms}
                                      aria-label="close"
                                      className="!p-0"
                                    >
                                      <CloseIcon className="border border-[#D1D5DB] rounded-md" />
                                    </IconButton>
                                  </div>
                                  <div className="bg-gray-300 h-[1px] w-full"></div>
                                </div>
                                <div className="w-full">
                                  <MoreThanNineRooms
                                    closeModal={handleCloseMoreThanNineRooms}
                                  />
                                </div>
                              </div>
                            </Box>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Modal>
              </div>
            </div>
          </div>

          {/* Hotel search / Region search / Country selection button */}
          <Tooltip title={getTooltipTitle()} placement="top" arrow>
            <span className="flex justify-end w-full">
              <button
                className={`items-center text-[#FFFFFF] font-medium lg:w-auto custom-lg:w-full py-3 px-5 custom-lg:px-2 w-full rounded-lg bg-[#002E8A] ${isButtonDisabled || loading ? "opacity-50 cursor-not-allowed" : "hover:bg-[#4826a3]"
                  }`}
                disabled={isButtonDisabled || loading}
                onClick={countrySelect ? handleSearch : handleCountrySelect}
              >
                {loading ? (
                  <CircularProgress size={18} color="inherit" />
                ) : (
                  <div className="items-center flex justify-center gap-3">
                    <p>Search stays</p>
                    <img src="/stay_home_img/search_icon.png" alt="" />
                  </div>
                )}
              </button>
            </span>
          </Tooltip>

          {/* Country selection modal */}
          <Modal
            className="!z-40"
            open={openCountrySelectModal}
            onClose={handleCloseCountrySelect}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={style}
              className="flex gap-5 p-3 sm:p-5 rounded-lg w-[90%] sm:w-[530px]"
            >
              <div className="flex justify-between items-center w-full py-1">
                <div className="w-full">
                  <p className="text-[#111928] text-[20px] font-medium">
                    Please select your country
                  </p>
                </div>
                <div className="border border-gray-300 rounded-lg w-[30px] h-[30px] flex justify-center">
                  <IconButton
                    onClick={handleCloseCountrySelect}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>

              <div>
                <p className="text-[#000000] font-normal">
                  Due to certain country restrictions, we require your residency
                  country before you can search for stays. Please select your
                  country to proceed. Thank you for understanding!
                </p>
              </div>

              <div className="flex w-full items-center border border-gray-300 gap-2 rounded-md">
                <CountryAutocomplete
                  options={country}
                  inputValue={inputValueCountry}
                  isoCode={isoCode}
                  countrySelect={countrySelect}
                  handleInputChange={handleCountryChange}
                  handleOptionChange={handleCountryOptionChange}
                />
              </div>

              <Tooltip
                title={
                  !countrySelect
                    ? "Please enter your country as it appears in your passport"
                    : ""
                }
                placement="top"
                arrow
              >
                <span className="w-full">
                  <button
                    onClick={countrySelect ? handleSearch : undefined}
                    className={`items-center text-[#FFFFFF] font-medium py-3 px-2 w-full rounded-lg bg-[#002E8A] min-w-fit ${!countrySelect || loading ? "opacity-50 cursor-not-allowed" : "hover:bg-[#4826a3]"
                      }`}
                    disabled={!countrySelect || loading}
                  >
                    {loading ? (
                      <CircularProgress size={18} color="inherit" />
                    ) : (
                      <div className="items-center flex justify-center gap-3">
                        <p>Show Results</p>
                        <img src="/stay_home_img/search_icon.png" alt="" />
                      </div>
                    )}
                  </button>
                </span>
              </Tooltip>
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default SearchStay;
