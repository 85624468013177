import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Utils/axiosConfig";
import {
  FetchHotelsResponse,
  SearchState,
  FetchSearchDataParams,
} from "../../Interfaces/reduxInterfaces";

const initialState: SearchState = {
  data: [],
  loading: false,
  error: null,
  isRegionChanged: false,
  totalCount: 0,
};

export const fetchHotelsByRegion = createAsyncThunk<
  FetchHotelsResponse,
  FetchSearchDataParams
>(
  "search/fetchHotelsByRegion",
  async (
    {
      page,
      region_id,
      checkin_date,
      checkout_date,
      guest_array,
      residency,
      sort,
    },
    { rejectWithValue, dispatch }
  ) => {
    dispatch(setIsRegionChanged(true));

    try {
      const requestData: FetchSearchDataParams = {
        page: page,
        region_id: region_id,
        checkin_date: checkin_date,
        checkout_date: checkout_date,
        guest_array: JSON.parse(guest_array),
        residency: residency,
        sort: sort,
      };

      const response = await axiosInstance.post("/hotelsByRegion", requestData);

      const data = response.data;

      return {
        hotels: data.hotels || [],
        totalCount: data.totalCount || 0,
      };
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.error || error?.message);
    }
  }
);

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    clearSearchResults: (state) => {
      state.data = [];
      state.error = null;
      state.totalCount = 0;
    },
    setIsRegionChanged: (state, action: PayloadAction<boolean>) => {
      state.isRegionChanged = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHotelsByRegion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchHotelsByRegion.fulfilled,
        (state, action: PayloadAction<FetchHotelsResponse>) => {
          state.loading = false;
          state.data = action.payload.hotels;
          state.totalCount = action.payload.totalCount;
          state.isRegionChanged = false;
        }
      )
      .addCase(
        fetchHotelsByRegion.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload || "Failed to fetch search data";
          state.isRegionChanged = false;
        }
      );
  },
});

export const { clearSearchResults, setIsRegionChanged } = searchSlice.actions;
export default searchSlice.reducer;
