import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axiosInstance from "../../Utils/axiosConfig";
import { CryptoState } from "../../Interfaces/reduxInterfaces";

const initialState: CryptoState = {
  addressData: null,
  loading: false,
  error: null,
};

interface FetchGenerateCryptoParams {
  s_id: string;
}

export const generateCryptoAddress = createAsyncThunk<
  any,
  FetchGenerateCryptoParams,
  { state: RootState; rejectValue: string }
>("crypto/generateAddress", async ({ s_id }, { getState, rejectWithValue }) => {
  try {
    const { userDetails } = getState().user;
    const { selectedCryptoWithNetwork, selectedFiat } = getState().currency;
    const { data: hotelInfo } = getState().hotelInfo;
    const bookingInfo = getState().search;
    const { roomInfo } = getState().room;

    // Prepare the request data as an object
    const requestData = {
      userDetails,
      selectedFiat,
      selectedCryptoWithNetwork,
      hotelInfo: hotelInfo.id,
      prebookInfo: "",
      bookingInfo,
      roomInfo,
    };

    // Make the POST request with axiosInstance
    const response = await axiosInstance.post(`/booking`, requestData);

    // Return the JSON data from the response
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message || "Request failed");
  }
});

export const generateCryptoAddressSlice = createSlice({
  name: "crypto",
  initialState,
  reducers: {
    resetCryptoState: (state) => {
      state.addressData = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateCryptoAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        generateCryptoAddress.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log("Fulfilled payload:", action.payload);
          state.loading = false;
          state.addressData = {
            orderId: action.payload.orderId,
            phone_number: action.payload.phone_number,
          };
        }
      )
      .addCase(
        generateCryptoAddress.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "Unknown error";
        }
      );
  },
});

export const { resetCryptoState } = generateCryptoAddressSlice.actions;
export default generateCryptoAddressSlice.reducer;
