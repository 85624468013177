import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from '../Layouts/appLayout';
import Home from '../Views/home';
import Search from '../Views/search';
import Hotel from '../Views/hotel';
import ErrorBoundary from '../Components/Shared/error';
import GuestDetails from '../Views/guestDetails';
import Payment from '../Views/payment';
import Login from '../Views/login';
import Unsubscribe from '../Views/unsubscribe';

const AppLayout: React.FC = () => {
  return <Layout />;
};

const appRouter = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/hotelsByRegion', element: <Search /> },
      { path: '/hotels', element: <Hotel /> },
      { path: '/hotelDetails/prebook', element: <GuestDetails /> },
    ],
  },
  { path: '/payment', element: <Payment /> },
  { path: '/login', element: <Login /> },
  { path: '/unsubscribe', element: <Unsubscribe /> },
], {
  future: {
    v7_relativeSplatPath: true,
    v7_fetcherPersist: true,
    // v7_startTransition: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true,
  },
});

const AppRouter: React.FC = () => {
  return <RouterProvider router={appRouter} />;
};

export default AppRouter;
