import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { format } from "date-fns";

import { fetchHotelsByRegion } from "../Redux/Slices/searchSlice";
import { AppDispatch, RootState } from "../Redux/store";
import SearchStay from "../Components/Shared/searchStay";
import Stepper from "../Components/Shared/stepper";
import SearchResult from "../Components/Views/Search/searchResult";
import Pagination from "../Components/Views/Search/pagination";
import SearchResultSkeleton from "../Components/Skeleton/searchResultSkeleton";
import { Option, Hotel } from "../Interfaces/componentInterfaces";

const Search: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();

  // Existing selectors for search parameters
  const searchVar = useSelector((state: RootState) => state.searchVar);
  const inputValue = searchVar.name || "";
  const group = searchVar.group || "";
  const designation = searchVar.designation || "";
  const countryCode = searchVar.countryCode || "";
  const residency = searchVar.residency || "";
  const isoCodeAlpha2 = searchVar.isoCodeAlpha2 || "";
  const inputSearch = searchVar.inputSearch || '';

  // Select search results state from Redux
  const {
    data: hotels,
    loading,
    error,
  } = useSelector((state: RootState) => state.search);

  const params = new URLSearchParams(location.search);
  const initialPage = parseInt(params.get("page") || "0");
  const initialRegionId = params.get("region_id") || "";
  const startDate = params.get("startDate") || "";
  const endDate = params.get("endDate") || "";
  const roomDetails = JSON.parse(params.get("roomDetails") || "[]");
  const initialSort = params.get("sort") || "popularity";
  const residencyParam = params.get("residency") || "mt";

  const pureRoomDetails = params.get("roomDetails") || "[]";

  let totalAdults = 0;
  let totalChildren = 0;
  roomDetails.forEach((room: { adults: number; children: number[] }) => {
    totalAdults += room.adults;
    totalChildren += room.children.length;
  });
  let totalGuests = totalAdults + totalChildren;

  const [selectedOption, setSelectedOption] = useState<Option>({
    id: initialRegionId,
    hid: 0,
    name: inputValue,
    group: group,
    designation: designation,
    countryCode: countryCode,
  });

  useEffect(() => {
    setSelectedOption({
      id: initialRegionId,
      hid: 0,
      name: inputValue,
      group: group,
      designation: designation,
      countryCode: countryCode,
    });
  }, [initialRegionId, inputValue, group, designation, countryCode]);

  const [showSearchStay, setShowSearchStay] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(initialPage || 0);
  const [selectedSortOption, setSelectedSortOption] =
    useState<string>(initialSort);

  const dateFormat = "dd MMM yyyy";
  const totalPages = 4;

  // Data fetching effect using Redux state management
  useEffect(() => {
    if (initialRegionId && startDate && endDate && pureRoomDetails) {
      dispatch(
        fetchHotelsByRegion({
          page: currentPage,
          region_id: initialRegionId,
          checkin_date: startDate,
          checkout_date: endDate,
          guest_array: pureRoomDetails,
          residency: residencyParam,
          sort: selectedSortOption !== "popularity" ? selectedSortOption : undefined,
        })
      );
    }
  }, [dispatch, currentPage, initialRegionId, startDate, endDate, pureRoomDetails, residencyParam, selectedSortOption]);

  const handleEditClick = () => {
    setShowSearchStay(!showSearchStay);
  };

  const mapDataToProps = (item: Hotel) => {
    const roomAmenities = item.amenity_groups?.find(
      (group) => group.group_name === "General"
    )?.amenities;
    const roomDummyAmenities = ["wifi", "s-no-smoke", "corn", "pc", "gym"];
    return {
      hid: Number(item.hid),
      image:
        item.images[0]?.replace("{size}", "1024x768") ||
        "https://via.placeholder.com/150",
      star: item.star_rating || 0,
      rating: item.rating || 0,
      reviews: item.reviews || 0,
      name: item.name || "No name available",
      address: item.address || "No address available",
      distance: item.distance || "",
      amenities: roomAmenities || roomDummyAmenities,
      id: item.id || "",
      daily_min: item.daily_min || 0,
    };
  };

  const checkinDateObj = startDate ? new Date(startDate) : undefined;
  const checkoutDateObj = endDate ? new Date(endDate) : undefined;

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("page", newPage.toString());
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const handleChangeSorting = (event: any) => {
    const sortOption = event.target.value;
    setSelectedSortOption(sortOption);

    const queryParams = new URLSearchParams(location.search);
    if (sortOption === "popularity") {
      queryParams.delete("sort");
    } else {
      queryParams.set("sort", sortOption);
    }
    queryParams.set("page", "0"); // Reset page to 0 when sorting changes
    setCurrentPage(0);

    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  return (
    <>
      <div className="md:hidden">
        <div className="h-10 bg-[#002E8A] flex justify-between w-full xl:container px-[1rem] mx-auto items-center text-[#FFFFFF] font-bold">
          <p className="text-[12px]">
            {inputValue && inputValue.length > 8 ? `${inputValue.substring(0, 10)}..` : inputValue}
          </p>
          <p className="text-[12px]">
            {startDate ? `${format(new Date(startDate), dateFormat)}` : ""} - {endDate ? `${format(new Date(endDate), dateFormat)}` : ""}
          </p>
          <p className="text-[12px]">{totalGuests} {totalGuests > 1 ? "Guests" : "Guest"}</p>
          <button onClick={handleEditClick}>
            <img src="/stay_search_img/edit-3.png" alt="Edit" />
          </button>
        </div>
      </div>

      {showSearchStay && (
        <div className="block md:hidden">
          <SearchStay
            initialInputValue={inputSearch}
            initialSelectedOption={selectedOption}
            initialStartDate={checkinDateObj}
            initialEndDate={checkoutDateObj}
            initialRooms={roomDetails}
            initialPage={initialPage}
            initialRegionId={initialRegionId}
            residency={residency}
            isoCodeAlpha2={isoCodeAlpha2}
          />
        </div>
      )}

      <div className="bg-[#F8F8F8]">
        <div className="stay-middle-div w-full xl:container mx-auto px-[1rem] md:px-[1.5rem] py-[2%] mx-w-[1536px]">
          <div className="hidden md:block">
            <SearchStay
              initialInputValue={inputSearch}
              initialSelectedOption={selectedOption}
              initialStartDate={checkinDateObj}
              initialEndDate={checkoutDateObj}
              initialRooms={roomDetails}
              initialPage={initialPage}
              initialRegionId={initialRegionId}
              residency={residency}
              isoCodeAlpha2={isoCodeAlpha2}
            />
          </div>
          <div className="py-4 md:py-10">
            <Stepper />
          </div>

          <div className="flex flex-row items-start mt-3 gap-10">
            <div className="w-full flex flex-col gap-3 md:gap-5 justify-end lg:w-[100%]">
              {!loading && hotels.length > 0 && (
                <div className="flex items-center justify-between gap-2">
                  <p>
                    {hotels.length} of{" "}
                    <span className="font-bold">{hotels.length} properties</span>
                  </p>
                  <div className="shadow">
                    <FormControl sx={{ minWidth: 220 }} fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        Sort Results
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedSortOption}
                        label="Sort Results"
                        onChange={handleChangeSorting}
                      >
                        <MenuItem value="popularity">Most Popular</MenuItem>
                        <MenuItem value="asc">Ascending Price</MenuItem>
                        <MenuItem value="desc">Descending Price</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}

              {!loading ? (
                <>
                  {hotels.length > 0 ? (
                    <>
                      {hotels.map((item: Hotel, index: number) => (
                        <SearchResult key={index} data={mapDataToProps(item)} />
                      ))}
                    </>
                  ) : (
                    <div className="text-center text-red-900">
                      <p>No search result found.</p>
                      {error && <p>{error}</p>}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {Array.from({ length: 9 }).map((_, index) => (
                    <SearchResultSkeleton key={index} />
                  ))}
                </>
              )}

              {!loading && hotels.length > 0 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  data={hotels}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Search;
