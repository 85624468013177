import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HashInfoState } from "../../Interfaces/reduxInterfaces";

const initialState: HashInfoState = {
  bookHash: "",
  updatedBookHash: "",
};

export const hashInfoSlice = createSlice({
  name: "hashInfo",
  initialState,
  reducers: {
    setBookHash: (state, action: PayloadAction<string>) => {
      state.bookHash = action.payload;
    },
    setUpdatedBookHash: (state, action: PayloadAction<string>) => {
      state.updatedBookHash = action.payload;
    },
  },
});

export const { setBookHash, setUpdatedBookHash } = hashInfoSlice.actions;
export default hashInfoSlice.reducer;

