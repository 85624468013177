import React, { useState, useEffect } from "react";
import { addDays, format } from 'date-fns';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';

import { setSearchParams } from "../../../Redux/Slices/searchVarSlice";
import { AppDispatch } from "../../../Redux/store";

const NextDestination: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [selectedRegion, setSelectedRegion] = useState<string>('Worldwide');

  const destinations = [
    { name: 'Paris', country: 'France', code: 'FR', group: 'Regions', designation: 'City', region: 'Europe', region_id: '2734', image: 'https://test.instacoins.travel/_next/image?url=%2Fassets%2F0b3129ea-65b9-4f0f-abc7-dc300f434897&w=3840&q=75' },
    { name: 'New York', country: 'United States of America', code: 'US', group: 'Regions', designation: 'City', region: 'North America', region_id: '2621', image: 'https://test.instacoins.travel/_next/image?url=%2Fassets%2Febdc95d8-105c-4d87-8200-eaf40a951a0e&w=3840&q=75' },
    { name: 'Madrid', country: 'Spain', code: 'ES', group: 'Regions', designation: 'City', region: 'Europe', region_id: '2198', image: 'https://test.instacoins.travel/_next/image?url=%2Fassets%2Fe3b514ee-d186-4d2d-b803-232fb918be9c&w=3840&q=75' },
    { name: 'Sydney', country: 'Australia', code: 'AU', group: 'Regions', designation: 'City', region: 'Pacific Ocean and Australia', region_id: '3341', image: 'https://test.instacoins.travel/_next/image?url=%2Fassets%2F9d2a9ded-0eb5-4ad0-a2dd-e88e935c227b&w=3840&q=75' },
    { name: 'Los Angeles', country: 'United States of America', code: 'US', group: 'Regions', designation: 'City', region: 'North America', region_id: '2011', image: 'https://test.instacoins.travel/_next/image?url=%2Fassets%2F76436dd4-8d7a-42fd-91aa-ad900e0095b2&w=3840&q=75' },
    { name: 'Abu Dhabi', country: 'United Arab Emirates', code: 'AE', group: 'Regions', designation: 'City', region: 'Middle East', region_id: '453', image: 'https://test.instacoins.travel/_next/image?url=%2Fassets%2Fcc64ae9a-30f0-4f4f-8012-b844c3d61ef5&w=3840&q=75' },
  ];

  const filteredDestinations = selectedRegion === 'Worldwide'
    ? destinations
    : destinations.filter(destination => destination.region === selectedRegion);

  const page = 0;
  const today = new Date();
  const oneDaysLater = addDays(today, 1);
  const startDate = today;
  const endDate = oneDaysLater;
  const rooms = [{ rooms: 1, adults: 2, children: [] }];

  const [checkinDate, setCheckinDate] = useState<string>('');
  const [checkoutDate, setCheckoutDate] = useState<string>('');

  useEffect(() => {
    if (startDate) {
      setCheckinDate(format(startDate, 'yyyy-MM-dd'));
    } else {
      setCheckinDate('N/A');
    }

    if (endDate) {
      setCheckoutDate(format(endDate, 'yyyy-MM-dd'));
    } else {
      setCheckoutDate('N/A');
    }
  }, [startDate, endDate]);

  const searchWithRegion = (code: any, country: any, region_id: any, name: any, group: any, designation: any) => {
    if (checkinDate && checkoutDate) {
      const validCheckinDate = !isNaN(new Date(checkinDate).getTime())
        ? checkinDate
        : null;
      const validCheckoutDate = !isNaN(new Date(checkoutDate).getTime())
        ? checkoutDate
        : null;
      dispatch(
        setSearchParams({
          startDate: validCheckinDate,
          endDate: validCheckoutDate,
          roomDetails: rooms,
          id: region_id || null,
          hid: null,
          name: name || null,
          designation: designation || null,
          group: group || null,
          countryCode: code || null,
          residency: country,
          isoCodeAlpha2: code || "mt"
        }),
      );
    }
    try {
      const queryParams = new URLSearchParams({
        page: page.toString(),
        region_id: region_id,
        startDate: checkinDate,
        endDate: checkoutDate,
        roomDetails: JSON.stringify(rooms),
        showAll: 'true',
        residency: code || "mt"
      }).toString();

      navigate(`/hotelsByRegion?${queryParams}`, {
      });
    } catch (error) {
      console.error('Failed to fetch search data:', error);
    }
  }

  return (
    <>
      <div className="next-destination-div ">
        <div className="div-heading mb-5">
          <h5 className="heading">Pick your next destination</h5>
          <p className="sub-heading">
            Search Flights to our most popular destinations
          </p>
        </div>

        <div className="overflow-x-auto hides-scrollbar">
          <div className="flex items-center gap-2 py-2 w-[1450px] md:justify-center md:flex-wrap md:w-full md:gap-4">
            <button onClick={() => setSelectedRegion('Worldwide')} className={`destination-btn ${selectedRegion === 'Worldwide' ? 'selected' : 'hover:bg-[#d1e4fc]'}`}>
              <p>Worldwide</p>
            </button>
            <button onClick={() => setSelectedRegion('Europe')} className={`destination-btn ${selectedRegion === 'Europe' ? 'selected' : 'hover:bg-[#d1e4fc]'}`}>
              <p>Europe</p>
            </button>
            <button onClick={() => setSelectedRegion('North America')} className={`destination-btn ${selectedRegion === 'North America' ? 'selected' : 'hover:bg-[#d1e4fc]'}`}>
              <p>North America</p>
            </button>
            <button onClick={() => setSelectedRegion('Asia')} className={`destination-btn ${selectedRegion === 'Asia' ? 'selected' : 'hover:bg-[#d1e4fc]'}`}>
              <p>Asia</p>
            </button>
            <button onClick={() => setSelectedRegion('Middle East')} className={`destination-btn ${selectedRegion === 'Middle East' ? 'selected' : 'hover:bg-[#d1e4fc]'}`}>
              <p>Middle East</p>
            </button>
            <button onClick={() => setSelectedRegion('Pacific Ocean and Australia')} className={`destination-btn ${selectedRegion === 'Pacific Ocean and Australia' ? 'selected' : 'hover:bg-[#d1e4fc]'}`}>
              <p>Pacific Ocean and Australia</p>
            </button>
            <button onClick={() => setSelectedRegion('South America')} className={`destination-btn ${selectedRegion === 'South America' ? 'selected' : 'hover:bg-[#d1e4fc]'}`}>
              <p>South America</p>
            </button>
            <button onClick={() => setSelectedRegion('Africa')} className={`destination-btn ${selectedRegion === 'Africa' ? 'selected' : 'hover:bg-[#d1e4fc]'}`}>
              <p>Africa</p>
            </button>
            <button onClick={() => setSelectedRegion('Central America and Caribbean')} className={`destination-btn ${selectedRegion === 'Central America and Caribbean' ? 'selected' : 'hover:bg-[#d1e4fc]'}`}>
              <p>Central America and Caribbean</p>
            </button>
          </div>
        </div>

        <div className="overflow-x-auto">
          <div className="destination-img-div w-[1200px] grid grid-cols-4 gap-2 mt-5 relative md:w-[1200px] sm:w-[1200px] lg:w-full lg:mt-7">
            {filteredDestinations.map((destination, index) => (
              <div onClick={() => searchWithRegion(destination.code, destination.country, destination.region_id, destination.name, destination.group, destination.designation)} key={index} className={`img-div col-span-1 relative h-64 cursor-pointer
                  ${index === 0 ? 'col-span-2' : index === 5 ? 'col-span-2' : index === 1 || 2 || 3 || 4 ? 'col-sapn-1' : null}`}>
                <img
                  className="object-cover w-full h-full rounded"
                  src={destination.image}
                  alt={destination.name}
                />
                <div className="country-img-div flex items-center justify-between absolute inset-x-0 p-4 bottom-0 text-white">
                  <div className="country-img-name">
                    <h4 className="place">{destination.name}</h4>
                    <p className="captal">{destination.country}</p>
                  </div>
                  <div className="country-img-detail">
                    <p className="start-from">Starting from</p>
                    <h4 className="amount">$ 250,00</h4>
                    <p className="longitude">Est. 0.00239 BTC</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
export default NextDestination;