import React from "react";

import { PaymentProps } from "../../../Interfaces/componentInterfaces";

const PaymentPriceSummary:React.FC<PaymentProps> = ({ order }: { order: any }) => {
  return (
    <>
      <div className="">
        <p className="text-[#27273F] font-semibold text-[24px] text-center">Price breakdown</p>

        <table>
          <tbody className="text-[#27273F] text-[18px] ">
            <tr className="">
              <td className="p-1">{order?.room_type}</td>
              <td className="p-1 text-end">
                {order?.no_of_rooms} X {parseFloat(order?.fiatPrice || 0).toFixed(2)} {order?.fiatType}
              </td>
            </tr>

            {order?.coupon_used === true ? (
              <tr>
                <td className="p-1 text-green-800">Coupon discount</td>
                <td className="p-1 text-green-800 text-end">
                  {order?.coupon_details?.couponType === "Amount" && (
                    <p>
                      {`- ${parseFloat(order?.coupon_details?.couponUpdatedValue || 0).toFixed(2)}`}
                      <span> {order?.coupon_details?.couponUpdatedCurrency || order?.fiatType}</span>
                    </p>
                  )}
                  {order?.coupon_details?.couponType === "Discount" && (
                    <p>
                      {`- ${parseFloat(order?.coupon_details?.couponDiscountRate || 0)} %`}
                    </p>
                  )}
                </td>
              </tr>
            ) : null}

            <tr className="">
              <td className="p-1">Subtotal</td>
              <td className="p-1 text-end">
                {order?.coupon_used === true ? (
                  <p>
                    {order?.fiatPriceAfterCoupon > 0 ? `${parseFloat(order?.fiatPriceAfterCoupon || 0).toFixed(2)}` : 'Free'}
                    <span> {order?.fiatType}</span>
                  </p>
                ) : (
                  <p>
                    {order?.fiatPrice}
                    <span> {order?.fiatType}</span>
                  </p>
                )}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr className="text-[#36374A] text-[18px] font-bold border-t-2 border-b-2">
              <td className="p-1">Amount to pay</td>
              <td className="p-1 text-end">
                <p>
                  {order?.crypto_price > 0 ? `${order?.crypto_price}` : 'Free'}
                  <span> {order?.crypto_type}/{order?.crypto_network}</span>
                </p>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
};
export default PaymentPriceSummary;
