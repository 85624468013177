import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { RootState } from '../Redux/store';
// import { searchVarSlice } from '../Redux/slices/searchVarSlice';
import SearchStay from '../Components/Shared/searchStay';
import StayInfo from '../Components/Views/Home/stayInfo';
import NextDestination from '../Components/Views/Home/nextDestination';
import StayAnywhere from '../Components/Views/Home/stayAnywhere';
import { Option } from '../Interfaces/componentInterfaces';

const Home: React.FC = () => {
  const searchVar = useSelector((state: RootState) => state.searchVar);

  const initialPage = parseInt('0');
  const initialRegionId = searchVar.id || '';
  const hId = searchVar.hid || 0;
  const inputValue = searchVar.name || '';
  const group = searchVar.group || '';
  const designation = searchVar.designation || '';
  const countryCode = searchVar.countryCode || '';
  const startDate = searchVar.startDate || '';
  const endDate = searchVar.endDate || '';
  const roomDetails = searchVar.roomDetails || [];
  const residency = searchVar.residency || '';
  const isoCodeAlpha2 = searchVar.isoCodeAlpha2 || '';
  const inputSearch = searchVar.inputSearch || '';

  const checkinDateObj = startDate ? new Date(startDate) : undefined;
  const checkoutDateObj = endDate ? new Date(endDate) : undefined;

  const [selectedOption, setSelectedOption] = useState<Option>({
    id: initialRegionId,
    hid: hId,
    name: inputValue,
    group: group,
    designation: designation,
    countryCode: countryCode,
  });
  useEffect(() => {
    setSelectedOption({
      id: initialRegionId, hid: hId, name: inputValue, group: group, designation: designation, countryCode: countryCode
    });
  }, [initialRegionId, hId, inputValue, group, designation, countryCode]);

  const authToken = localStorage.getItem('authToken');

  if (!authToken) {
    return <Navigate to='/login' replace />;
  }
  return (
    <>
      <div className='main-page bg-[#F8F8F8]'>
        <div className='back-img'>
          <div className='w-full px-[1rem] md:px-[1.5rem] stay-top-div xl:container py-[8%] mx-auto mx-w-[1536px]'>
            <div className='main-heading-div pb-[8%]'>
              <h1 className='main-heading'>Stay anywhere worldwide!</h1>
            </div>
            <div className='mt-[2%]'>
              <SearchStay
                initialInputValue={inputSearch}
                initialSelectedOption={selectedOption}
                initialStartDate={checkinDateObj}
                initialEndDate={checkoutDateObj}
                initialRooms={roomDetails}
                initialPage={initialPage}
                initialRegionId={initialRegionId}
                residency={residency}
                isoCodeAlpha2={isoCodeAlpha2}
              />
            </div>
          </div>
        </div>
        <div className='px-[1rem] md:px-[1.5rem] xl:container mx-auto mt-[2%] mx-w-[1536px]'>
          <StayInfo />
          <NextDestination />
          <StayAnywhere />
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Home;
