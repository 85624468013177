import React from 'react'
import { Skeleton } from '@mui/material';

const CouponApplySkeleton: React.FC = () => {
    return (
        <div>
            <div className='mt-5'>
                <Skeleton className='w-[200px] h-[20px] lg:w-[300px] lg:h-[40px]' />
                <div className='my-3 flex justify-between gap-4'>
                    <div className='w-full'>
                        <Skeleton
                            variant="rectangular"
                            height={45}
                            className="rounded-md"
                        />
                    </div>
                    <div className='w-[25%]'>
                        <Skeleton
                            variant="rectangular"
                            height={45}
                            className="rounded-md"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CouponApplySkeleton;
