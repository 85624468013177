import React, { useEffect, useState } from 'react'

import axiosInstance from '../../../Utils/axiosConfig';
import { HotelReviewsProps, ReviewsData } from '../../../Interfaces/componentInterfaces';

const HotelReviews: React.FC<HotelReviewsProps> = ({ hotelId }) => {
  const [reviewsData, setReviewsData] = useState<ReviewsData>();
  const [visibleReviews, setVisibleReviews] = useState(3);

  useEffect(() => {
    const fetchReviewData = async () => {
      if (!hotelId) return;
      const body = {
        id: hotelId
      };

      try {
        const response = await axiosInstance.post(
          `/reviews`, body
        );
        const data = response.data;

        if (response.status === 200 && data.status === "success") {
          setReviewsData(data.data);
        } else {
          console.log("Reviews fetching error");
        }
      } catch (error) {
        console.log("Error fetching reviews:", error);
      }
    };

    fetchReviewData();
  }, [hotelId]);

  const validRatingsCount = Object.values(reviewsData?.detailed_ratings || {}).filter(
    (value) => typeof value === "number" && value > 0
  ).length;

  const handleLoadMore = () => {
    setVisibleReviews((prev) => prev + 3);
  };

  return (
    <>
      {reviewsData && (
        <div id="reviews" className='flex flex-col gap-10'>
          <div className='w-full mt-8 lg:mt-12 mx-auto flex flex-col justify-center'>
            <div className='flex items-center gap-5'>
              <div className='flex w-[50px] h-[50px] bg-[#002E8A] items-center justify-center rounded-md text-[#FFFFFF] text-[24px] font-bold'>{reviewsData?.rating}</div>
              <div>
                <p className='text-[24px] font-bold text-[#001A4D]'>Excellent</p>
                <div className='flex gap-3'>
                  <p className='text-[14px] text-[#001A4D]'>{reviewsData?.reviews?.length > 1 ? `${reviewsData?.reviews?.length} reviews` : `${reviewsData?.reviews?.length} review`}</p>
                  <p className='text-[14px] text-[#001A4D]'>{validRatingsCount > 1 ? `${validRatingsCount} ratings` : `${validRatingsCount} rating`}</p>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap gap-5 mt-6 md:mt-10">
              {Object.entries(reviewsData?.detailed_ratings || {})
                .filter(([_, value]) => value && value > 0)
                .sort((a, b) => (b[1] ?? 0) - (a[1] ?? 0))
                .map(([key, value], index) => (
                  <div
                    key={index}
                    className="flex flex-col w-full gap-6 md:w-[calc(20%-1rem)] md:min-w-[180px]"
                  >
                    <div className="flex flex-col gap-2 md:gap-3">
                      <div className="text-[18px] text-[#001A4D] flex gap-3">
                        <span className="text-[#002E8A] font-bold">{value ?? "0.0"}</span>
                        <span>{key.charAt(0).toUpperCase() + key.slice(1)}</span>
                      </div>
                      <div className="w-full h-[25px] border border-[#002E8A] rounded-full overflow-hidden md:border-none">
                        <div
                          className="h-full"
                          style={{
                            background: `linear-gradient(90deg, #002E8A ${value ? value * 10 : 0}%, #EBDFFF ${value ? value * 10 : 0.01}%, #FFFFFF 100%)`,
                            width: "100%",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="">
            <table>
              <tbody className="flex flex-col gap-8 w-full">
                {reviewsData?.reviews?.slice(0, visibleReviews).map((review) => (
                  <tr key={review.id} className="w-full grid lg:grid-cols-[25%_74%] gap-3">
                    {/* Left Column: Author and Room Info */}
                    <td className="w-full flex items-start p-0">
                      <div className='w-full'>
                        <div className='flex justify-between gap-2 w-full'>
                          <div className="flex items-center gap-2 mb-3">
                            <div className="flex items-center justify-center w-[40px] h-[40px] bg-[#D4D4D8] rounded-full">
                              {/* Placeholder for profile picture */}
                            </div>
                            <div>
                              <p className="font-bold text-[#001A4D] lg:text-black">{review.author}</p>
                              <p className='text-[#001A4D] lg:text-black'>{review.traveller_type ?? "Unspecified"}</p>
                            </div>
                          </div>
                          <div className='block lg:hidden'>
                            <div className="flex items-center justify-center w-[50px] h-[50px] text-[24px] font-bold text-[#FFFFFF] bg-[#002E8A] rounded-md">
                              {review.rating}
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-col gap-2">
                          <div className="flex items-start gap-2">
                            <img src="/stay_hotel_img/king-bed.svg" alt="Bed Icon" />
                            <p className="text-[#374151] text-[14px]">{review.room_name ?? "Room Type Not Specified"}</p>
                          </div>
                          <div className="flex items-start gap-2">
                            <img src="/stay_hotel_img/calendar2.svg" alt="Calendar Icon" />
                            <p className="text-[#374151] text-[14px]">
                              {review.nights} {review.nights > 1 ? "nights" : "night"} - {new Date(review.created).toLocaleDateString()}
                            </p>
                          </div>
                          <div className="flex items-start gap-2">
                            <img src="/stay_hotel_img/users.svg" alt="Guests Icon" />
                            <p className="text-[#374151] text-[14px]">
                              {review.adults + review.children} guests
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>

                    {/* Right Column: Review Content */}
                    <td className="w-full flex items-start p-0">
                      <div className="flex flex-col gap-5 w-full">
                        <div className="flex gap-5 w-full justify-between">
                          <div className='w-full'>
                            <p className="text-[18px] font-bold mb-2">{review.room_name}</p>
                            <div className='flex flex-col gap-2'>
                              {review.review_plus && (
                                <p className="text-[14px]">Plus: {review.review_plus}</p>
                              )}
                              {review.review_minus && (
                                <p className="text-[14px]">Minus: {review.review_minus}</p>
                              )}
                            </div>
                          </div>
                          <div className='hidden lg:block'>
                            <div className="flex items-center justify-center w-[50px] h-[50px] text-[24px] font-bold text-[#FFFFFF] bg-[#002E8A] rounded-md">
                              {review.rating}
                            </div>
                          </div>
                        </div>

                        {/* <div className="flex items-center justify-center mt-5 lg:justify-end gap-5">
                          <button className="flex items-center gap-2">
                            <img src="/stay_hotel_img/like.svg" alt="Like Icon" />
                            <span className="text-[#074EE8]">Helpful</span>
                          </button>
                          <button className="flex items-center gap-2">
                            <img src="/stay_hotel_img/dislike.svg" alt="Dislike Icon" />
                            <span className="text-[#E02424]">Not helpful</span>
                          </button>
                        </div> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Load More Button */}
          {reviewsData?.reviews?.length > visibleReviews && (
            <div className='flex justify-center'>
              <button
                className='px-5 py-3 border border-gray-300 rounded-md text-[#1F2A37] hover:text-[#2e3e52] hover:border-gray-200 font-medium'
                onClick={handleLoadMore}
              >
                Load more reviews
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default HotelReviews;
