import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { TextField } from '@mui/material';

import { MyStayStatusProps } from '../../Interfaces/componentInterfaces';

const MyStayStatus: React.FC<MyStayStatusProps> = ({ closeModal }) => {
  const [orderId, setOrderId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const navigate = useNavigate();

  const handleSubmit = () => {
    const sanitizedPhoneNumber = phoneNumber.replace(/^\+/, '');
    navigate(
      `/payment?orderId=${orderId}&phone_number=${sanitizedPhoneNumber}`,
    );
    closeModal();
  };

  return (
    <div className='flex flex-col gap-5'>
      <div>
        <p>
          Please provide the order number and the phone number
          used for your reservation.
        </p>
      </div>
      <div className='flex flex-col gap-3'>
        <p>Order number*</p>
        <TextField
          id='orderId'
          label='Order ID'
          type='text'
          fullWidth
          size='small'
          value={orderId}
          onChange={(e) => setOrderId(e.target.value.trim())}
        />
      </div>

      <div className='flex flex-col gap-3'>
        <p>Phone number*</p>

        <div style={{ width: '100%' }}>
          <PhoneInput
            international
            value={phoneNumber}
            onChange={(value: string | undefined) => {
              if (value !== undefined) {
                setPhoneNumber(value);
              } else {
                setPhoneNumber('');
              }
            }}
            style={{
              width: '100%',
              height: '40px',
              border: '1px solid #c4c4c4',
              borderRadius: '4px',
              padding: '10px',
              fontSize: '16px',
            }}
          />
        </div>
      </div>

      <div className='w-full'>
        <button
          onClick={handleSubmit}
          disabled={!orderId || !phoneNumber}
          className={`w-full bg-[#002E8A] text-[#FFFFFF] font-medium rounded-lg flex items-center justify-center border py-3 ${!orderId || !phoneNumber
            ? 'opacity-50 cursor-not-allowed'
            : 'opacity-100 hover:bg-[#4826a3]'
            }`}
        >
          Find my trip
        </button>
      </div>
    </div>
  );
};

export default MyStayStatus;
