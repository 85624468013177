import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { UserDetails, UserState, Guest } from "../../Interfaces/reduxInterfaces";

const initialState: UserState = {
  userDetails: {
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    email: "",
    additionalRequests: "",
    guestDetails: [],
  },
  inputErrors: {},
  loading: false,
  error: null,
};

export const validateForm = createAsyncThunk(
  "user/validateForm",
  async (_, { getState }) => {
    const state: any = getState();
    const errors: Partial<Omit<UserDetails, "guestDetails">> & {
      guestDetails?: any[][] | undefined;
    } = {};

    // 🛠 Main User Validation
    if (!state.user.userDetails.title) {
      errors.title = "Required";
    }

    const firstName = state.user.userDetails.firstName?.trim();
    if (!firstName) {
      errors.firstName = "Required";
    } else if (!/^[A-Za-z]+$/.test(firstName)) {
      errors.firstName = "Only alphabets are allowed";
    } else if (firstName.length > 20) {
      errors.firstName = "Can't exceed 20 characters";
    }

    if (state.user.userDetails.middleName) {
      const middleName = state.user.userDetails.middleName?.trim();
      if (!/^[A-Za-z]+$/.test(middleName)) {
        errors.middleName = "Only alphabets are allowed";
      } else if (middleName.length > 20) {
        errors.middleName = "Can't exceed 20 characters";
      }
    }

    const lastName = state.user.userDetails.lastName?.trim();
    if (!lastName) {
      errors.lastName = "Required";
    } else if (!/^[A-Za-z]+$/.test(lastName)) {
      errors.lastName = "Only alphabets are allowed";
    } else if (lastName.length > 20) {
      errors.lastName = "Can't exceed 20 characters";
    }

    if (!state.user.userDetails.phone) {
      errors.phone = "Required";
    } else {
      const phoneNumber = parsePhoneNumberFromString(
        state.user.userDetails.phone
      );
      if (!phoneNumber || !phoneNumber.isValid()) {
        errors.phone = "Invalid phone number or country code";
      } else if (!phoneNumber.country) {
        errors.phone = "Country code is required";
      }
    }

    if (state.user.userDetails.additionalRequests) {
      const additionalRequests =
        state.user.userDetails.additionalRequests.trim();
      if (additionalRequests.length > 100) {
        errors.additionalRequests = "Can't exceed 100 characters";
      }
    }

    // 🛠 Email Validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!state.user.userDetails.email) {
      errors.email = "Required";
    } else if (!emailRegex.test(state.user.userDetails.email)) {
      errors.email = "Invalid email format";
    } else if (state.user.userDetails.email.length > 35) {
      errors.email = "Can't exceed 35 characters";
    }

    // 🛠 Guest Details Validation
    if (state.user.userDetails.guestDetails.length > 0) {
      errors.guestDetails = state.user.userDetails.guestDetails.map(
        (room: any) =>
          room.guests.map((guest: any) => {
            const guestErrors: {
              first_name?: string;
              last_name?: string;
            } = {};

            const firstName = guest.first_name?.trim();
            const lastName = guest.last_name?.trim();

            if (!firstName) {
              guestErrors.first_name = "Required";
            } else if (!/^[A-Za-z]+$/.test(firstName)) {
              guestErrors.first_name = "Only alphabets are allowed";
            } else if (firstName.length > 20) {
              guestErrors.first_name = "Can't exceed 20 characters";
            }

            if (!lastName) {
              guestErrors.last_name = "Required";
            } else if (!/^[A-Za-z]+$/.test(lastName)) {
              guestErrors.last_name = "Only alphabets are allowed";
            } else if (lastName.length > 20) {
              guestErrors.last_name = "Can't exceed 20 characters";
            }

            return Object.keys(guestErrors).length ? guestErrors : {};
          })
      );

      const allEmpty = errors?.guestDetails?.every((roomErrors) =>
        roomErrors.every((error) => Object.keys(error).length === 0)
      );
      if (allEmpty) {
        delete errors.guestDetails;
      }
    }
    return errors;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetails: (state, action: PayloadAction<Partial<UserDetails>>) => {
      state.userDetails = { ...state.userDetails, ...action.payload };
    },
    addGuestDetails: (
      state,
      action: PayloadAction<{ roomIndex: number; guest: Guest }>
    ) => {
      const { roomIndex, guest } = action.payload;
      if (!state.userDetails.guestDetails[roomIndex]) {
        state.userDetails.guestDetails[roomIndex] = { guests: [] };
      }
      state.userDetails.guestDetails[roomIndex].guests.push(guest);
    },
    clearGuestDetails: (state) => {
      state.userDetails.guestDetails = [];
    },
    setErrors: (
      state,
      action: PayloadAction<
        Partial<Omit<UserDetails, "guestDetails">> & {
          guestDetails?: any[][] | undefined;
        }
      >
    ) => {
      state.inputErrors = action.payload;
    },

    clearErrors: (state) => {
      state.inputErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(validateForm.fulfilled, (state, action) => {
        state.inputErrors = action.payload;
      })
      .addCase(validateForm.pending, (state) => {
        state.loading = true;
      })
      .addCase(validateForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Validation failed";
      });
  },
});

export const {
  setUserDetails,
  addGuestDetails,
  clearGuestDetails,
  setErrors,
  clearErrors,
} = userSlice.actions;

export default userSlice.reducer;
