import React, { useState } from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Modal,
  Box,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppDispatch, RootState } from '../../../Redux/store';
import { setRoomInfo } from '../../../Redux/Slices/roomInfoSlice';
import { roomGroupAmenities } from '../../Shared/allImages';
import { SelectedCrypto, CurrencyRate, RoomProps } from '../../../Interfaces/componentInterfaces';

const HotelRoomGroup: React.FC<RoomProps> = ({ room, roomCount }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const hid = params.get('hid');
  const startDate = params.get('checkin') || '';
  const endDate = params.get('checkout') || '';

  const guests = params.get('guests');
  const residency = params.get('residency');

  const diffInMs = Math.abs(
    new Date(endDate).getTime() - new Date(startDate).getTime(),
  );
  const totalNight = Math.round(diffInMs / (1000 * 60 * 60 * 24));

  const { roomName, roomArray: roomVariants } = room;
  const rates: CurrencyRate[] = useSelector(
    (state: RootState) => state.currency.cryptoRates,
  );
  const fiatRates: CurrencyRate[] = useSelector(
    (state: RootState) => state.currency.fiatRates,
  );

  const selectedCrypto: SelectedCrypto = useSelector(
    (state: RootState) => state.currency.selectedCryptoWithNetwork,
  );

  const selectedFiat: string = useSelector(
    (state: RootState) => state.currency.selectedFiat || 'USD', // Provide a default
  );

  // Helper Functions
  const formatPrice = (price: number, conversionRate: number): number => {
    return Number(price) / Number(conversionRate);
  };

  // Find Crypto Rate
  const cryptoRateItem = rates.find(
    (item: CurrencyRate) =>
      item.ccy2 === selectedCrypto.cryptoName &&
      item.blockchain2 === selectedCrypto.blockchain2,
  );

  const cryptoRate = cryptoRateItem ? cryptoRateItem.rate : 0; // Default to 0 if not found

  // Find Fiat Rate
  const selectedFiatArray = fiatRates.find(
    (item: CurrencyRate) => item.ccy2 === selectedFiat,
  );

  // Ensure PriceRate is always a number
  const PriceRate = selectedFiat === 'EUR' ? 1 : selectedFiatArray?.rate ?? 1;

  // Determine decimal precision
  const precFiatVal = selectedFiatArray ? Number(selectedFiatArray.prec) : 2;

  const beddingTypes = Array.from(
    new Set(
      roomVariants?.map(
        (variant) => variant?.room_data_trans?.bedding_type || '',
      ),
    ),
  ).sort((a, b) => a.localeCompare(b));

  // State to keep track of selected bedding type
  const [selectedBeddingType, setSelectedBeddingType] = useState(
    beddingTypes[0],
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Handle bedding type change
  const handleBeddingTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSelectedBeddingType(event.target.value);
  };

  // Handle modal open and close
  const handleShowAllPhotos = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const selectedVariants = roomVariants?.filter(
    (variant) =>
      (variant?.room_data_trans?.bedding_type || '') === selectedBeddingType,
  );

  // Fallback for selectedVariant
  const selectedVariant =
    selectedVariants && selectedVariants.length > 0
      ? selectedVariants[0]
      : null;

  const handleGuestDetails = (variant: any) => {
    const book_hash = variant.book_hash;
    dispatch(
      setRoomInfo({
        roomInfo: {
          roomCount: roomCount,
          room: variant,
        },
      }),
    );
    // navigate(`/guestDetails/${id}`);
    navigate(
      `/hotelDetails/prebook?book_hash=${book_hash}&checkin=${startDate}&checkout=${endDate}&guests=${guests}&residency=${residency}&hid=${hid}`,
    );
  };

  return (
    <>
      {selectedVariant ? (
        <div className='mb-3 md:mb-5' key={roomName}>
          <Accordion defaultExpanded className='shadow-none w-full'>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className='text-[#FFFFFF]' />}
              aria-controls='panel1-content'
              id='panel1-header'
              className='!bg-[#000F2E] !rounded-tl-lg !rounded-tr-lg'
            >
              <div className='font-bold text-[#FFFFFF] text-[14px] md:text-[18px]'>
                {roomCount > 1 ? `${roomCount} x ${roomName}` : roomName}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className='grid lg:grid-cols-[35%_64%] gap-3'>
                <div>
                  {/* Images */}
                  {selectedVariant?.room_group?.images?.length === 1 ? (
                    <div>
                      <div className='grid grid-cols-12'>
                        <div
                          className='col-span-12 rounded-2xl object-cover overflow-hidden cursor-pointer'
                          onClick={handleShowAllPhotos}
                        >
                          <img
                            className='w-full h-full object-cover'
                            src={selectedVariant?.room_group?.images[0]?.replace(
                              '{size}',
                              '1024x768',
                            )}
                            alt=''
                          />
                        </div>
                      </div>

                      <div className='flex w-full justify-center mt-4'>
                        <p
                          className='border-b border-black cursor-pointer'
                          onClick={handleShowAllPhotos}
                        >
                          Show photo
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {selectedVariant?.room_group?.images?.length > 1 ? (
                    <div>
                      <div className='grid grid-cols-12 gap-2'>
                        {/* Main Image */}
                        <div
                          className='rounded-2xl object-cover overflow-hidden cursor-pointer col-span-8'
                          onClick={handleShowAllPhotos}
                        >
                          <img
                            className='w-full h-full object-cover'
                            src={selectedVariant?.room_group?.images[0]?.replace(
                              '{size}',
                              '1024x768',
                            )}
                            alt=''
                          />
                        </div>

                        {/* Other Images (only show 2 small images initially) */}
                        <div className='col-span-4 grid grid-rows-2 gap-2'>
                          {selectedVariant &&
                            selectedVariant.room_group &&
                            selectedVariant?.room_group?.images?.length > 1 &&
                            selectedVariant?.room_group?.images
                              ?.slice(1, 3)
                              .map((image: string, index: number) => (
                                <div
                                  key={index}
                                  className='row-span-1 rounded-2xl object-cover overflow-hidden cursor-pointer'
                                  onClick={handleShowAllPhotos}
                                >
                                  <img
                                    className='w-full h-full object-cover'
                                    src={image?.replace('{size}', '240x240')}
                                    alt=''
                                  />
                                </div>
                              ))}
                        </div>
                      </div>

                      {/* Show all photos link */}
                      <div className='flex w-full justify-center mt-4'>
                        {selectedVariant?.room_group?.images?.length > 3 ? (
                          <p
                            className='border-b border-black cursor-pointer'
                            onClick={handleShowAllPhotos}
                          >
                            Show all photos
                          </p>
                        ) : (
                          <p
                            className='border-b border-black cursor-pointer'
                            onClick={handleShowAllPhotos}
                          >
                            Show photos
                          </p>
                        )}
                      </div>
                    </div>
                  ) : null}

                  {/* Slider Modal */}
                  <Modal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby='modal-title'
                    aria-describedby='modal-description'
                  >
                    <Box className='w-[95%] h-[45%] lg:w-[85%] lg:h-[90%] my-[40%] mx-auto p-2 lg:p-3 bg-[#FFFFFF] flex items-center lg:my-[2.5%]'>
                      <Swiper
                        spaceBetween={10}
                        slidesPerView={1}
                        loop={true}
                        autoplay={{
                          delay: 3000,
                          disableOnInteraction: false,
                        }}
                        pagination={{ clickable: true }}
                        navigation={true}
                        modules={[Autoplay, Navigation, Pagination]}
                      >
                        {selectedVariant?.room_group?.images?.map(
                          (image: string, index: number) => (
                            <SwiperSlide key={index}>
                              <img
                                className='w-full h-full'
                                src={image?.replace('{size}', '1024x768')}
                                alt=''
                              />
                            </SwiperSlide>
                          ),
                        )}
                      </Swiper>
                      <IconButton
                        onClick={handleCloseModal}
                        aria-label='close'
                        sx={{ position: 'absolute', top: 10, right: 10 }}
                      >
                        <CloseIcon className='text-[#FFFFFF]' />
                      </IconButton>
                    </Box>
                  </Modal>

                  {/* Bedding type radio buttons */}
                  <RadioGroup
                    aria-label={`bed-type`}
                    name={`${roomName}-bed-type-name`}
                    value={selectedBeddingType}
                    onChange={handleBeddingTypeChange}
                    row
                    className='mt-2 flex justify-center'
                  >
                    {beddingTypes?.map((beddingType: any, index: number) => (
                      <FormControlLabel
                        key={`${index}-${roomName}`}
                        value={beddingType}
                        control={<Radio />}
                        label={beddingType
                          .split('-')
                          .map(
                            (word: any) =>
                              word.charAt(0).toUpperCase() + word.slice(1),
                          )
                          .join(' ')}
                      />
                    ))}
                  </RadioGroup>

                  {/* Room amenities */}
                  <div className='flex justify-between mt-5'>
                    <div className='flex flex-col gap-3'>
                      {selectedVariant?.room_group?.room_amenities
                        ?.slice(
                          0,
                          Math.ceil(
                            selectedVariant?.room_group?.room_amenities
                              ?.length / 2,
                          ),
                        )
                        .map((amenity: string, index: number) => (
                          <div key={index} className='flex items-center gap-2'>
                            <img
                              className='w-[20px] h-[20px]'
                              src={
                                (roomGroupAmenities as any)[amenity]?.icon ||
                                '/stay_hotel_img/amenities/check.svg'
                              }
                              alt=''
                            />
                            <p className='text-[#046C4E]'>
                              {(roomGroupAmenities as any)[amenity]?.label ||
                                'Amenity'}
                            </p>
                          </div>
                        ))}
                    </div>
                    <div className='flex flex-col gap-3'>
                      {selectedVariant?.room_group?.room_amenities
                        ?.slice(
                          Math.ceil(
                            selectedVariant?.room_group?.room_amenities
                              ?.length / 2,
                          ),
                        )
                        .map((amenity: string, index: number) => (
                          <div key={index} className='flex items-center gap-2'>
                            <img
                              className='w-[20px] h-[20px]'
                              src={
                                (roomGroupAmenities as any)[amenity]?.icon ||
                                '/stay_hotel_img/amenities/check.svg'
                              }
                              alt=''
                            />
                            <p className='text-[#046C4E]'>
                              {(roomGroupAmenities as any)[amenity]?.label ||
                                'Amenity'}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                {/* Table with Meal Options and Pricing */}
                <div className='overflow-auto'>
                  <table className='min-w-[595px] lg:max-w-full w-full border-collapse border border-gray-200'>
                    <thead>
                      <tr className='bg-gray-100'>
                        <th className='text-start p-2 border border-gray-200'>
                          Meal options
                        </th>
                        <th className='text-center p-2 border border-gray-200'>
                          Sleeps
                        </th>
                        <th className='text-start p-2 border border-gray-200'>
                          Your price includes
                        </th>
                        {/* <th className='w-[25%] text-start p-2 border border-gray-200'>
                          Refundability
                        </th> */}
                        <th className='text-end p-2 border border-gray-200'>
                          Price for {totalNight}{' '}
                          {totalNight > 1 ? 'nights' : 'night'}
                        </th>
                        <th className='text-center p-2 border border-gray-200'>
                          Book
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedVariants?.map((variant, index) => {
                        const payment =
                          variant?.payment_options?.payment_types[0];
                        return (
                          <tr
                            key={`${index}-${selectedBeddingType}`}
                            className='hover:bg-gray-100'
                          >
                            <td className='p-2 border border-gray-200 font-bold'>
                              {payment.cancellation_penalties
                                .free_cancellation_before ? (
                                <span className='text-yellow-600'>
                                  {variant.meal_data.value
                                    ? variant?.meal_data.value
                                        ?.replace(/-/g, ' ')
                                        .toLowerCase()
                                        .replace(/^\w/, (c: any) =>
                                          c.toUpperCase(),
                                        )
                                    : ''}
                                </span>
                              ) : (
                                <span className=''>
                                  {variant.meal_data.value
                                    ? variant?.meal_data.value
                                        ?.replace(/-/g, ' ')
                                        .toLowerCase()
                                        .replace(/^\w/, (c: any) =>
                                          c.toUpperCase(),
                                        )
                                    : ''}
                                </span>
                              )}
                            </td>
                            <td className='p-2 border border-gray-200 '>
                              <div className='flex items-center gap-2 justify-center'>
                                {[...Array(variant.rg_ext.capacity)].map(
                                  (_, idx) => (
                                    <img
                                      key={idx}
                                      className='w-[20px] h-[20px]'
                                      src='/stay_hotel_img/User.svg'
                                      alt='Sleeps'
                                    />
                                  ),
                                )}
                              </div>
                            </td>
                            <td className='p-2 border border-gray-200'>
                              {variant.amenities_data?.map(
                                (item: any, index: number) => (
                                  <div
                                    className='flex items-center gap-2 flex-wrap'
                                    key={index}
                                  >
                                    <img
                                      src='/stay_hotel_img/check.svg'
                                      alt=''
                                    />{' '}
                                    <p className='text-[14px] text-[#046C4E]'>
                                      {item}
                                    </p>
                                  </div>
                                ),
                              )}
                            </td>
                            {/* <td className='p-2 border border-gray-200'>
                              {payment.cancellation_penalties
                                .free_cancellation_before ? (
                                <>
                                  <p className='text-[#046C4E] font-medium'>
                                    FREE cancellation
                                  </p>
                                  <p className='text-sm text-[#046C4E]'>
                                    before{' '}
                                    {new Date(
                                      payment.cancellation_penalties.free_cancellation_before,
                                    ).toLocaleString()}
                                  </p>
                                </>
                              ) : (
                                <p className='text-red-500 font-medium'>
                                  Not refundable
                                </p>
                              )}
                            </td> */}
                            <td className='p-2 border border-gray-200 text-end'>
                              <p className='font-bold'>
                                {(payment.show_amount * PriceRate).toFixed(
                                  precFiatVal,
                                )}{' '}
                                {selectedFiat}
                              </p>
                              <p className='text-[#002E8A] text-sm'>
                                {cryptoRate
                                  ? formatPrice(
                                      payment.show_amount,
                                      cryptoRate,
                                    ).toFixed(
                                      cryptoRateItem?.prec
                                        ? Number(cryptoRateItem.prec)
                                        : 2,
                                    )
                                  : 'N/A'}{' '}
                                {selectedCrypto.cryptoName}
                              </p>
                            </td>
                            <td className='p-2 border border-gray-200 text-center'>
                              <button
                                className='w-full px-3 py-2 bg-[#002E8A] hover:bg-[#4826a3] rounded-md text-[#FFFFFF] font-medium'
                                onClick={() => handleGuestDetails(variant)}
                              >
                                Book
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      ) : null}
    </>
  );
};

export default HotelRoomGroup;
