import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import searchSlice from "./Slices/searchSlice";
import searchVarSlice from "./Slices/searchVarSlice";
import currencySlices from "./Slices/currencySlices";
import testSlice from "./Slices/testSlice";
import hashInfoSlice from "./Slices/hashInfoSlice";
import userSlice from "./Slices/userSlice";
import orderStatusSlice from "./Slices/orderStatusSlice";
import generateCryptoAddressSlice from "./Slices/generateCryptoAddressSlice";
import hotelInfoSlice from "./Slices/hotelInfoSlice";
import roomInfoSlice from "./Slices/roomInfoSlice";
import couponSlice from "./Slices/couponSlice";

const rootReducer = combineReducers({
  search: searchSlice,
  searchVar: searchVarSlice,
  crypto: generateCryptoAddressSlice,
  currency: currencySlices,
  hashInfo: hashInfoSlice,
  hotelInfo: hotelInfoSlice,
  test: testSlice,
  user: userSlice,
  orderStatus: orderStatusSlice,
  room: roomInfoSlice,
  coupon: couponSlice,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "hotelInfo",
    "roomInfo",
    "searchVar",
    "crypto",
    "prebook",
    "hashInfo",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
