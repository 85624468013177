import React, { useState } from 'react'
import QRCode from 'react-qr-code';
import { Tooltip } from '@mui/material';

import { PaymentOrderTimeProps } from '../../../Interfaces/componentInterfaces';

const BarcodePay: React.FC<PaymentOrderTimeProps > = ({ order, timeLeft }) => {
    const [copySuccess, setCopySuccess] = useState(false);

    const textToCopy = order?.wallet_address;
    const handleCopy = () => {
        navigator.clipboard.writeText(textToCopy).then(() => {
            setCopySuccess(true);
            setTimeout(() => {
                setCopySuccess(false);
            }, 2000);
        }).catch((err) => {
            setCopySuccess(false);
            console.error('Failed to copy text: ', err);
        });
    };

    return (
        <div>
            {order?.orderStatus === 'waiting_payment' ? (
                <div className="mt-6 flex flex-col gap-3">
                    <div className="flex flex-col lg:flex-row lg:justify-between gap-1">
                        <p>Order number</p>
                        <p className="font-bold break-all">{order?.order_number}</p>
                    </div>

                    {
                        timeLeft === "00:00:00" ?
                            <div className="flex justify-between">
                                <p>The offer is expired. Please search again.</p>
                            </div>
                            :
                            <div>
                                <div className="flex justify-between">
                                    <p>This offer will expire in</p>
                                    <div className="flex gap-2 items-center">
                                        <img className="w-[20px] h-[20px]" src="/stay_payment_img/countdown.svg" alt="" />
                                        <p>{timeLeft}</p>
                                    </div>
                                </div>

                                <p>To complete your purchase, please send the indicated amount to the wallet address below.</p>

                                <div className="flex flex-col gap-2">
                                    <p className="font-bold text-[18px]">Wallet Address</p>
                                    <div className="flex justify-between gap-2 items-start">
                                        <p className='break-all'>{textToCopy}</p>
                                        <Tooltip title={copySuccess ? "Copied!" : "Copy"} arrow>
                                            <button onClick={handleCopy} aria-label="Copy text">
                                                <img src="/stay_payment_img/copy.svg" alt="copy" />
                                            </button>
                                        </Tooltip>
                                    </div>

                                    <div className='w-full flex justify-center'>
                                        <QRCode className='w-[50%] lg:w-[20%]'
                                            value={order?.wallet_address}
                                        />
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            ) : order?.orderStatus === 'partial_payment' ? (
                <div className="mt-6 flex flex-col gap-3">
                    <div className="flex flex-col lg:flex-row lg:justify-between gap-1">
                        <p>Order number</p>
                        <p className="font-bold">{order?.order_number}</p>
                    </div>

                    {
                        timeLeft === "00:00:00" ?
                            <div className="flex justify-between">
                                <p>The offer is expired. Please search again.</p>
                            </div>
                            :
                            <div>
                                <div className="flex justify-between">
                                    <p>This offer will expire in</p>
                                    <div className="flex gap-2 items-center">
                                        <img className="w-[20px] h-[20px]" src="/stay_payment_img/countdown.svg" alt="" />
                                        <p>{timeLeft}</p>
                                    </div>
                                </div>

                                <p>To complete your purchase, please send the indicated amount to the wallet address below.</p>

                                <div className="flex flex-col gap-2">
                                    <p className="font-bold text-[18px]">Wallet Address</p>
                                    <div className="flex justify-between gap-2 items-center">
                                        <p className='break-all'>{textToCopy}</p>
                                        <Tooltip title={copySuccess ? "Copied!" : "Copy"} arrow>
                                            <button onClick={handleCopy} aria-label="Copy text">
                                                <img src="/stay_payment_img/copy.svg" alt="copy" />
                                            </button>
                                        </Tooltip>
                                    </div>

                                    <div className='w-full flex justify-center'>
                                        <QRCode className='w-[50%] lg:w-[20%]'
                                            value={order?.wallet_address}
                                        />
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            ) : order?.orderStatus === 'payment_received' ? (
                <div className=''>
                    <div className="mt-6 flex flex-col gap-3">
                        <div className="flex flex-col lg:flex-row lg:justify-between">
                            <p>Order number</p>
                            <p className="font-bold">{order?.order_number}</p>
                        </div>
                    </div>

                    <div className='text-center mt-5'>
                        <p className='text-[18px] font-medium'>Payment received with</p>
                        <div className='flex justify-center mt-3'>
                            <div className='flex items-center gap-3 border border-[#000000] px-5 py-2 rounded-lg'>
                                <img className="w-[32px] h-[32px]" src="/stay_thank_you_img/check.svg" alt="" />
                                <p className='text-[32px] font-bold'>{order?.crypto_network}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ) : order?.orderStatus === 'processing_booking' ? (
                <div className=''>
                    <div className="mt-6 flex flex-col gap-3">
                        <div className="flex flex-col lg:flex-row lg:justify-between">
                            <p>Order number</p>
                            <p className="font-bold">{order?.order_number}</p>
                        </div>
                    </div>

                    <div className='text-center mt-5'>
                        <p className='text-[18px] font-medium'>Payment received with</p>
                        <div className='flex justify-center mt-3 mb-2'>
                            <div className='flex items-center gap-3 border border-[#000000] px-5 py-2 rounded-lg'>
                                <img className="w-[32px] h-[32px]" src="/stay_thank_you_img/check.svg" alt="" />
                                <p className='text-[32px] font-bold'>{order?.crypto_network}</p>
                            </div>
                        </div>
                        <p className='text-[18px] font-medium'>We are completing your booking!</p>
                    </div>
                </div>
            ) : order?.orderStatus === 'booking_completed' ? (
                <div className=''>
                    <div className="mt-6 flex flex-col gap-3">
                        <div className="flex flex-col lg:flex-row lg:justify-between">
                            <p>Order number</p>
                            <p className="font-bold">{order?.order_number}</p>
                        </div>
                    </div>

                    <div className='text-center mt-5'>
                        <p className='text-[18px] font-medium'>Paid with</p>
                        <div className='flex justify-center mt-3'>
                            <div className='flex items-center gap-3 border border-[#000000] px-5 py-2 rounded-lg'>
                                <img className="w-[32px] h-[32px]" src="/stay_thank_you_img/check.svg" alt="" />
                                <p className='text-[32px] font-bold'>{order?.crypto_network}</p>
                            </div>
                        </div>
                        <p className='text-[18px] font-medium'>Booking completed!</p>
                    </div>
                </div>
            ) : order?.orderStatus === 'booking_error' ? (
                <div className=''>
                    <div className="mt-6 flex flex-col gap-3">
                        <div className="flex flex-col lg:flex-row lg:justify-between">
                            <p>Order number</p>
                            <p className="font-bold">{order?.order_number}</p>
                        </div>
                    </div>

                    <div className='text-center mt-5'>
                        <p className='text-[18px] font-medium'>The hotel booking error</p>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default BarcodePay;
