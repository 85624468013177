import React from "react";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/joy/Autocomplete";
import Box from "@mui/material/Box";

import { UserSelectionProps } from "../../Interfaces/componentInterfaces";

const UserSelection: React.FC<UserSelectionProps> = ({
    room,
    index,
    removeRoom,
    handleSetAdultCount,
    handleSetChildCount,
    handleSetChildAge,
}) => {
    return (
        <div key={index} className="px-3 sm:px-5">
            <div className="flex items-center justify-between">
                <p className="font-medium text-[16px]">{`Room ${index + 1}`}</p>
                {index > 0 && (
                    <div>
                        <div className="w-full mb-2">
                            <div className="left-0 absolute w-full h-[1px] bg-[#E5E7EB]"></div>
                        </div>
                        <div>
                            <IconButton aria-label="delete" onClick={removeRoom}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </div>
                )}
            </div>

            {/* Adults Count */}
            <div className="flex flex-row justify-between items-center mt-3">
                <div className="flex flex-col sm:gap-2 sm:flex-row">
                    <p>Adults</p>
                    <p className="text-[#6B7280]">(age 18+)</p>
                </div>
                <div className="flex items-center gap-2">
                    <button
                        className={`px-3 py-1 border rounded-full ${room.adults <= 1 ? "shadow-inner bg-slate-100" : "shadow-lg bg-[#FFFFFF]"
                            }`}
                        onClick={() => handleSetAdultCount(Math.max(room.adults - 1, 1))}
                        disabled={room.adults <= 1}
                    >
                        -
                    </button>
                    <div className="mx-1 w-[6px]">
                        <span>{room.adults}</span>
                    </div>
                    <button
                        className={`px-3 py-1 border rounded-full ${room.adults >= 6 ? "shadow-inner bg-slate-100" : "shadow-lg bg-[#FFFFFF]"
                            }`}
                        onClick={() => handleSetAdultCount(Math.min(room.adults + 1, 6))}
                        disabled={room.adults >= 6}
                    >
                        +
                    </button>
                </div>
            </div>

            {/* Children Count */}
            <div className="flex flex-row justify-between items-center mt-3">
                <div className="flex flex-col sm:gap-2 sm:flex-row">
                    <p>Children</p>
                    <p className="text-[#6B7280]">(age 1 - 17)</p>
                </div>
                <div className="flex items-center gap-2">
                    <button
                        className={`px-3 py-1 border rounded-full ${room.children.length <= 0 ? "shadow-inner bg-slate-100" : "shadow-lg bg-[#FFFFFF]"
                            }`}
                        onClick={() => handleSetChildCount(Math.max(room.children.length - 1, 0))}
                        disabled={room.children.length <= 0}
                    >
                        -
                    </button>
                    <div className="w-[6px] mx-1">
                        <span>{room.children.length}</span>
                    </div>
                    <button
                        className={`px-3 py-1 border rounded-full ${room.children.length >= 4 ? "shadow-inner bg-slate-100" : "shadow-lg bg-[#FFFFFF]"
                            }`}
                        onClick={() => handleSetChildCount(Math.min(room.children.length + 1, 4))}
                        disabled={room.children.length >= 4}
                    >
                        +
                    </button>
                </div>
            </div>

            {/* Children Age Selection */}
            {room.children.map((age, childIndex) => (
                <Box sx={{ marginTop: "10px" }} key={childIndex}>
                    <Autocomplete
                        className="w-full"
                        options={Array.from({ length: 17 }, (_, i) => ({
                            value: i + 1,
                            label: i + 1 === 1 ? `${i + 1} year` : `${i + 1} years`,
                        }))}
                        getOptionLabel={(option) => option.label}
                        value={
                            age
                                ? {
                                    value: age,
                                    label: age === 1 ? `${age} year` : `${age} years`,
                                }
                                : null
                        }
                        onChange={(event, newValue) => {
                            if (newValue?.value !== undefined) {
                                handleSetChildAge(childIndex, newValue.value);
                            }
                        }}
                        renderOption={(props, option) => (
                            <Box
                                component="li"
                                sx={{
                                    padding: "6px 8px",
                                    cursor: "pointer",
                                    alignItems: "start",
                                    "&:hover": { backgroundColor: "#CFD8DC" },
                                }}
                                key={option.value}
                                {...props}
                            >
                                <p className="flex leading-5 items-start">{option.label}</p>
                            </Box>
                        )}
                        slotProps={{
                            listbox: {
                                className: "!z-50",
                                sx: { "& > li": { display: "flex", margin: "1px" } },
                            },
                        }}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                    />
                </Box>
            ))}
        </div>
    );
};

export default UserSelection;
