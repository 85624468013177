import React from 'react'
import { Skeleton } from '@mui/material';

const SearchResultSkeleton: React.FC = () => {
    return (
        <>
            <div className='hidden md:block mb-2'>
                <div className="flex gap-7 items-center w-full">
                    <Skeleton
                        variant="rectangular"
                        height={200}
                        className="w-[35%] rounded-xl"
                    />
                    <div className="w-full flex justify-between">
                        <div className="w-[45%] flex flex-col gap-2">
                            <Skeleton height={40} />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <div className='w-full flex gap-4'>
                                <Skeleton height={50} width={40} />
                                <Skeleton height={50} width={40} />
                                <Skeleton height={50} width={40} />
                                <Skeleton height={50} width={40} />
                                <Skeleton height={50} width={40} />
                            </div>
                        </div>
                        <div className="w-[20%]">
                            <div className='flex justify-end'>
                                <Skeleton height={40} width={200} />
                            </div>
                            <div className='flex justify-end'>
                                <Skeleton width={150} />
                            </div>
                            <div className='flex justify-end'>
                                <Skeleton width={120} />
                            </div>
                            <div className='flex justify-end'>
                                <Skeleton
                                    variant="rectangular"
                                    height={50}
                                    className="w-[100%] rounded-xl mt-5"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='block md:hidden'>
                <div className='mb-3'>
                    <div className='flex w-full gap-4 items-center'>
                        <Skeleton
                            variant="rectangular"
                            height={100}
                            width={300}
                            className="w-[100%] rounded-xl"
                        />
                        <div className='w-full flex flex-col gap-3'>
                            <Skeleton />
                            <Skeleton width={200} />
                        </div>
                    </div>
                    <div className='w-full flex gap-10 mt-2'>
                        <div className='w-full'>
                            <Skeleton />
                            <Skeleton />
                        </div>
                        <div className='w-full'>
                            <Skeleton />
                            <Skeleton />
                        </div>
                    </div>
                    <div className='w-full mt-3 flex'>
                        <div className='w-full flex gap-4'>
                            <Skeleton height={50} width={40} />
                            <Skeleton height={50} width={40} />
                            <Skeleton height={50} width={40} />
                            <Skeleton height={50} width={40} />
                        </div>
                        <div className='w-[30%]'>
                            <div className='flex justify-end'>
                                <Skeleton height={40} width={160} />
                            </div>
                            <div className='flex justify-end'>
                                <Skeleton width={130} />
                            </div>
                            <div className='flex justify-end'>
                                <Skeleton width={100} />
                            </div>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <Skeleton
                            variant="rectangular"
                            height={45}
                            className="w-[100%] rounded-lg"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
export default SearchResultSkeleton;