import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchStateVar } from "../../Interfaces/reduxInterfaces";

const initialState: SearchStateVar = {
  startDate: "",
  endDate: "",
  roomDetails: [{ rooms: 1, adults: 2, children: [] }],
  id: "",
  hid: 0,
  name: "",
  designation: "",
  group: "",
  countryCode: "",
  residency: "",
  isoCodeAlpha2: "",
  inputSearch: "",
};

export const searchVarSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchParams: (state, action: PayloadAction<Partial<SearchStateVar>>) => {
      state.startDate = action.payload.startDate
        ? new Date(action.payload.startDate).toISOString()
        : null;
      state.endDate = action.payload.endDate
        ? new Date(action.payload.endDate).toISOString()
        : null;
      state.roomDetails = action.payload.roomDetails ?? state.roomDetails;
      state.id = action.payload.id ?? state.id;
      state.hid = action.payload.hid ?? state.hid;
      state.name = action.payload.name ?? state.name;
      state.designation = action.payload.designation ?? state.designation;
      state.group = action.payload.group ?? state.group;
      state.countryCode = action.payload.countryCode ?? state.countryCode;
      state.residency = action.payload.residency ?? state.residency;
      state.isoCodeAlpha2 = action.payload.isoCodeAlpha2 ?? state.isoCodeAlpha2;
      state.inputSearch = action.payload.inputSearch ?? state.inputSearch;
    },
  },
});

export const { setSearchParams } = searchVarSlice.actions;
export default searchVarSlice.reducer;
