import React from 'react'
import parse from 'html-react-parser';

import { HotelHouseRulesProps } from '../../../Interfaces/componentInterfaces';

const HotelHouseRules: React.FC<HotelHouseRulesProps> = ({ data }) => {
  const checkInTime = data?.check_in_time || "00:00:00";
  const [checkInHour] = checkInTime.split(':').map(Number);
  const checkInProgressWidth = `${(checkInHour / 24) * 100}%`;

  const checkOutTime = data?.check_out_time || "00:00:00";
  const [checkOutHour] = checkOutTime.split(':').map(Number);
  const checkOutProgressWidth = `${(checkOutHour / 24) * 100}%`;

  return (
    <div id="houseRules">
      <div className='mt-8 mx-auto flex justify-center'>
        <div className='w-full lg:w-[60%] flex flex-col gap-8'>
          <div className='w-full lg:w-[60%] flex flex-col gap-4 md:gap-8'>
            <p className='text-[20px] font-bold'>House rules</p>
            <p className='text-[#001A4D]'>{data?.name} takes special requests - add in the next step!</p>
            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-2 w-[150px] lg:w-[25%]'>
                <img src="/stay_hotel_img/calendar.svg" alt="" />
                <p>Check-in</p>
              </div>
              <div className='w-[75%]'>
                <div className='flex items-center justify-end gap-24'>
                  <p>{data?.check_in_time}</p>
                  <p className='text-end'>00:00</p>
                </div>
                <div className='w-[100%] h-[25px] border border-[#002E8A] bg-[#002E8A] rounded-full flex overflow-hidden justify-start'>
                  <div
                    className='h-[25px] bg-[#FFFFFF]'
                    style={{ width: checkInProgressWidth }}
                  ></div>
                </div>
                <p className='text-center'>{data?.check_in_time} - 00:00</p>
                <div>
                </div>
              </div>
            </div>

            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-2 w-[150px] lg:w-[25%]'>
                <img src="/stay_hotel_img/calendar.svg" alt="" />
                <p>Check-out</p>
              </div>
              <div className='w-[75%]'>
                <div className='flex items-center justify-end gap-24'>
                  <p>{data?.check_out_time}</p>
                  <p>00:00</p>
                </div>
                <div className='w-[100%] h-[25px] border border-[#002E8A] bg-[#002E8A] rounded-full flex overflow-hidden justify-start'>
                  <div
                    className='h-[25px] bg-[#FFFFFF]'
                    style={{ width: checkOutProgressWidth }}
                  ></div>
                </div>
                <p className='text-center'>{data?.check_out_time} - 00:00</p>
              </div>
            </div>
          </div>

          <div className='mt-3 md:mt-8'>
            {
              data?.metapolicy_extra_info?.length > 0 ? (
                <div>
                  {parse(data?.metapolicy_extra_info)}
                </div>
              ) : null}
            <table>
              <tbody className='info-tbody'>
                {/* {data?.policy_struct?.map((policy, policyIndex) => (
                  <React.Fragment key={policyIndex}>
                    <tr>
                      <td className='font-bold pt-4 pl-0 pr-5 align-top'>{policy.title}</td>
                      <td className='pl-2'>
                        {policy?.paragraphs?.map((paragraph, paragraphIndex) => (
                          <div key={paragraphIndex} className='pt-1 p-0 leading-normal'>
                            {parse(paragraph)}
                          </div>
                        ))}
                      </td>
                    </tr>
                  </React.Fragment>
                ))} */}

                {data?.metapolicy_struct?.cot?.length > 0 ? (
                  <tr>
                    <td className='font-bold pt-2 pl-0 pr-5 align-top lg:min-w-[100px]'>Cot: </td>
                    <td className='pl-2'>
                      {data?.metapolicy_struct?.cot?.map((cotItem: any, index: number) => (
                        <div key={index} className='pt-0 p-0 leading-normal'>
                          Amount: {cotItem.amount || ''},
                          Currency: {cotItem.currency || ''},
                          Inclusion: {cotItem.inclusion || ''},
                          Price: {cotItem.price || ''},
                          Price unit: {cotItem.price_unit || ''}
                        </div>
                      ))}
                    </td>
                  </tr>
                ) : null}


                {data?.metapolicy_struct?.extra_bed?.length > 0 ? (
                  <tr>
                    <td className='font-bold pt-2 pl-0 pr-5 align-top lg:min-w-[100px]'>Extra bed: </td>
                    <td className='pl-2'>
                      {data?.metapolicy_struct?.extra_bed?.map((bed: any, index: number) => (
                        <div key={index} className='pt-0 p-0 leading-normal'>
                          Amount: {bed.amount || ''},
                          Currency: {bed.currency || ''},
                          Inclusion: {bed.inclusion || ''},
                          Price: {bed.price || ''},
                          Price unit: {bed.price_unit || ''}
                        </div>
                      ))}
                    </td>
                  </tr>
                ) : null}


                {data?.metapolicy_struct?.deposit?.length > 0 ? (
                  <tr>
                    <td className='font-bold pt-2 pl-0 pr-5 align-top lg:min-w-[100px]'>Deposit: </td>
                    <td className='pl-2'>
                      {data?.metapolicy_struct?.deposit?.map((deposit: any, index: number) => (
                        <div key={index} className='pt-0 p-0 leading-normal'>
                          Availability: {deposit.availability || ''},
                          Currency: {deposit.currency || ''},
                          Deposit type: {deposit.deposit_type || ''},
                          Payment type: {deposit.payment_type || ''},
                          Price: {deposit.price || ''},
                          Price unit: {deposit.price_unit || ''},
                          Pricing method: {deposit.pricing_method || ''}
                        </div>
                      ))}
                    </td>
                  </tr>
                ) : null}

                {data?.metapolicy_struct?.children?.length > 0 ? (
                  <tr>
                    <td className='font-bold pt-2 pl-0 pr-5 align-top lg:min-w-[100px]'>Children: </td>
                    <td className='pl-2'>
                      {data?.metapolicy_struct?.children?.map((child: any, index: number) => (
                        <div key={index} className='pt-0 p-0 leading-normal'>
                          Price: {child.price || ''},
                          Age start: {child.age_start || ''},
                          Age end: {child.age_end || ''},
                          Currency: {child.currency || ''},
                          Extra bed: {child.extra_bed || ''}
                        </div>
                      ))}
                    </td>
                  </tr>
                ) : null}

                {data?.metapolicy_struct?.children_meal?.length > 0 ? (
                  <tr>
                    <td className='font-bold pt-2 pl-0 pr-5 align-top lg:min-w-[100px]'>Children meal: </td>
                    <td className='pl-2'>
                      {data?.metapolicy_struct?.children_meal?.map((meal: any, index: number) => (
                        <div key={index} className='pt-0 p-0 leading-normal'>
                          Age start: {meal.age_start || ''},
                          Age end: {meal.age_end || ''},
                          Currency: {meal.currency || ''},
                          Inclusion: {meal.inclusion || ''},
                          Price: {meal.price || ''},
                          Meal type: {meal.meal_type || ''}
                        </div>
                      ))}
                    </td>
                  </tr>
                ) : null}


                {data?.metapolicy_struct?.meal?.length > 0 ? (
                  <tr>
                    <td className='font-bold pt-2 pl-0 pr-5 align-top lg:min-w-[100px]'>Meal: </td>
                    <td className='pl-2'>
                      {data?.metapolicy_struct?.meal?.map((meal: any, index: number) => (
                        <div key={index} className='pt-0 p-0 leading-normal'>
                          Price: {meal.price || ''},
                          Currency: {meal.currency || ''},
                          Inclusion: {meal.inclusion || ''},
                          Meal type: {meal.meal_type || ''}
                        </div>
                      ))}
                    </td>
                  </tr>
                ) : null}


                {data?.metapolicy_struct?.parking?.length > 0 ? (
                  <tr>
                    <td className='font-bold pt-2 pl-0 pr-5 align-top lg:min-w-[100px]'>Parking: </td>
                    <td className='pl-2'>
                      {data?.metapolicy_struct?.parking?.map((parking: any, index: number) => (
                        <div key={index} className='pt-0 p-0 leading-normal'>
                          Currency: {parking.currency || ''},
                          Inclusion: {parking.inclusion || ''},
                          Price: {parking.price || ''},
                          Price unit: {parking.price_unit || ''},
                          Territory type: {parking.territory_type || ''}
                        </div>
                      ))}
                    </td>
                  </tr>
                ) : null}


                {data?.metapolicy_struct?.internet?.length > 0 ? (
                  <tr>
                    <td className='font-bold pt-2 pl-0 pr-5 align-top lg:min-w-[100px]'>Internet: </td>
                    <td className='pl-2'>
                      {data?.metapolicy_struct?.internet?.map((internet: any, index: number) => (
                        <div key={index} className='pt-0 p-0 leading-normal'>
                          Price: {internet.price || ''},
                          Currency: {internet.currency || ''},
                          Inclusion: {internet.inclusion || ''},
                          Work area: {internet.work_area || ''}
                          Price unit: {internet.price_unit || ''},
                          Internet type: {internet.internet_type || ''},
                        </div>
                      ))}
                    </td>
                  </tr>
                ) : null}


                {data?.metapolicy_struct?.pets?.length > 0 ? (
                  <tr>
                    <td className='font-bold pt-2 pl-0 pr-5 align-top lg:min-w-[100px]'>Pets: </td>
                    <td className='pl-2'>
                      {data?.metapolicy_struct?.pets?.map((pet: any, index: number) => (
                        <div key={index} className='pt-0 p-0 leading-normal'>
                          Currency: {pet.currency || ''},
                          Inclusion: {pet.inclusion || ''},
                          Pets type: {pet.pets_type || ''},
                          Price: {pet.price || ''},
                          Price unit: {pet.price_unit || ''}
                        </div>
                      ))}
                    </td>
                  </tr>
                ) : null}

                {data?.metapolicy_struct?.visa ? (
                  <tr>
                    <td className='font-bold pt-2 pl-0 pr-5 align-top lg:min-w-[100px]'>Visa: </td>
                    <td className='pl-2'>
                      <div className='pt-0 p-0 leading-normal'>
                        Visa support: {data?.metapolicy_struct?.visa?.visa_support}
                      </div>
                    </td>
                  </tr>
                ) : null}

                {data?.metapolicy_struct?.shuttle?.length > 0 ? (
                  <tr>
                    <td className='font-bold pt-2 pl-0 pr-5 align-top lg:min-w-[100px]'>Shuttle: </td>
                    <td className='pl-2'>
                      {data?.metapolicy_struct?.shuttle?.map((shuttle: any, index: number) => (
                        <div key={index} className='pt-0 p-0 leading-normal'>
                          Currency: {shuttle.currency || ''},
                          Inclusion: {shuttle.inclusion || ''},
                          Destination type: {shuttle.destination_type || ''},
                          Price: {shuttle.price || ''},
                          Shuttle type: {shuttle.shuttle_type || ''}
                        </div>
                      ))}
                    </td>
                  </tr>
                ) : null}

                {/* {data?.metapolicy_struct?.shuttle?.length > 0 ? (
                  <tr>
                    <td className='font-bold pt-4 pl-0 pr-5 align-top'>Shuttle: </td>
                    <td className='pl-2'>
                      <div className='pt-1 p-0 leading-normal'>
                        Currency: {data?.metapolicy_struct?.shuttle?.[0].currency}, Inclusion: {data?.metapolicy_struct?.shuttle?.[0].inclusion}, Destination type: {data?.metapolicy_struct?.shuttle?.[0].destination_type}, Price: {data?.metapolicy_struct?.shuttle?.[0].price}, Shuttle type: {data?.metapolicy_struct?.shuttle?.[0].shuttle_type},
                      </div>
                    </td>
                  </tr>
                ) : null} */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HotelHouseRules;