import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoomState, RoomInfo } from '../../Interfaces/reduxInterfaces';

const loadRoomInfoFromSession = (): RoomInfo | undefined => {
  const storedRoomInfo = sessionStorage.getItem(`roomInfo`);
  return storedRoomInfo ? JSON.parse(storedRoomInfo) : undefined;
};

const getInitialRoomState = (): RoomState => {
  const storedRoomInfo = loadRoomInfoFromSession();
  return {
    roomInfo: storedRoomInfo || undefined,
    error: undefined,
  };
};

export const roomSlice = createSlice({
  name: 'room',
  initialState: getInitialRoomState(),
  reducers: {
    setRoomInfo: (
      state,
      action: PayloadAction<{ roomInfo: RoomInfo }>,
    ) => {
      const { roomInfo } = action.payload;
      state.roomInfo = roomInfo;
      sessionStorage.setItem(`roomInfo`, JSON.stringify(roomInfo));
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
  },
});

export const { setRoomInfo, setError } = roomSlice.actions;
export default roomSlice.reducer;
