import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
// import axiosInstance from "../../Utils/axiosConfig";
import axios from "axios";
import {
  CurrencyState,
  Rate,
  SelectedCryptoWithNetwork,
} from "../../Interfaces/reduxInterfaces";

const initialState: CurrencyState = {
  fiatRates: [],
  cryptoRates: [],
  selectedFiat: "",
  selectedCryptoWithNetwork: {
    cryptoName: "",
    blockchain2: "",
  },
  status: "idle",
  error: null,
};

export const fetchCurrencies = createAsyncThunk<
  Rate[],
  void,
  { rejectValue: string }
>("currency/fetchCurrencies", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get<{
      error: string;
      rates: Rate[];
    }>("https://zey38dubbd.execute-api.eu-north-1.amazonaws.com/rates", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    // ('/currencyRates');

    const data = response.data;

    if (response.status !== 200) {
      throw new Error(data.error || "Failed to fetch currencies");
    }

    return data.rates;
  } catch (error: any) {
    return rejectWithValue(error.message || "Failed to fetch currencies");
  }
});

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    setSelectedFiat: (state, action: PayloadAction<string>) => {
      state.selectedFiat = action.payload;
    },
    setSelectedCryptoWithNetwork: (
      state,
      action: PayloadAction<SelectedCryptoWithNetwork>
    ) => {
      state.selectedCryptoWithNetwork = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrencies.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchCurrencies.fulfilled,
        (state, action: PayloadAction<Rate[]>) => {
          state.status = "succeeded";

          const fiatRates: Rate[] = [];
          const cryptoRates: Rate[] = [];

          action.payload.forEach((rate) => {
            const cryptoName = rate.ccy2 || "Unknown";
            const blockchain2 = rate.blockchain2;

            if (blockchain2 === "FIAT") {
              fiatRates.push(rate);
            } else if (blockchain2 !== "FIAT" && rate.ccy1 === "EUR") {
              cryptoRates.push({ ...rate, cryptoName, blockchain2 });
            }
          });

          state.fiatRates = fiatRates;
          state.cryptoRates = cryptoRates;

          if (
            !state.selectedCryptoWithNetwork.cryptoName &&
            cryptoRates.length > 0
          ) {
            state.selectedCryptoWithNetwork = {
              cryptoName: cryptoRates[0].cryptoName,
              blockchain2: cryptoRates[0].blockchain2,
            };
          }

          if (!state.selectedFiat && fiatRates.length > 0) {
            state.selectedFiat = fiatRates[0].ccy1;
          }
        }
      )
      .addCase(
        fetchCurrencies.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.status = "failed";
          state.error = action.payload || "Failed to load data";
        }
      );
  },
});

export const { setSelectedFiat, setSelectedCryptoWithNetwork } =
  currencySlice.actions;

export default currencySlice.reducer;
