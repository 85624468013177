import React from "react";
import Autocomplete from "@mui/joy/Autocomplete";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Box } from "@mui/material";

import { CountryAutocompleteProps } from "../../Interfaces/componentInterfaces";

const CountryAutocomplete: React.FC<CountryAutocompleteProps> = ({
    options,
    inputValue,
    isoCode,
    countrySelect,
    handleInputChange,
    handleOptionChange,
}) => {
    return (
        <Autocomplete
            freeSolo
            className="py-2.5 w-full"
            startDecorator={
                isoCode ? (
                    <div className="flex items-center gap-2">
                        <img
                            className="w-[20px] h-[15px] object-cover"
                            src={`https://flagcdn.com/w20/${isoCode.toLowerCase()}.png`}
                            alt={countrySelect}
                        />
                        <div className="w-[1px] h-[15px] bg-black"></div>
                    </div>
                ) : (
                    <LocationOnOutlinedIcon />
                )
            }
            options={options}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            onChange={handleOptionChange}
            value={countrySelect}
            filterOptions={(options, state) => {
                return options.filter((option) =>
                    option.name
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                );
            }}
            isOptionEqualToValue={(option, value) => {
                return option.name === value?.name;
            }}
            slotProps={{
                input: {
                    placeholder: "Select your residency country",
                },
                root: {
                    sx: {
                        border: "none !important",
                        outline: "none !important",
                        boxShadow: "none !important",
                        color: countrySelect ? "black" : "red !important",
                    },
                },
                listbox: {
                    className: "!z-50",
                    sx: {
                        "& > li": {
                            display: "flex",
                            margin: "1px",
                            "& > img": { marginRight: "9px" },
                        },
                    },
                },
            }}
            renderOption={(props, option) => {
                const { ...optionProps } = props;
                return (
                    <Box
                        component="li"
                        sx={{
                            "& > img": { mr: 2, flexShrink: 0 },
                            padding: "6px 8px",
                            cursor: "pointer",
                            alignItems: "start",
                            "&:hover": {
                                backgroundColor: "#CFD8DC",
                            },
                        }}
                        {...optionProps}
                    >
                        {option.code && (
                            <img
                                loading="lazy"
                                className="mr-[10px] mt-[3px] w-[20px] h-[15px] object-cover"
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                alt={option.code}
                            />
                        )}
                        <p className="flex leading-5 items-start">
                            {option.name}
                        </p>
                    </Box>
                );
            }}
        />
    );
};

export default CountryAutocomplete;
