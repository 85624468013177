import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HotelInfoState } from '../../Interfaces/reduxInterfaces';

const initialState: HotelInfoState = {
  data: null,
  status: 'idle',
  error: null,
};

export const hotelInfoSlice = createSlice({
  name: 'hotelInfo',
  initialState,
  reducers: {
    setHotelInfo: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    setFetchStatus: (
      state,
      action: PayloadAction<HotelInfoState['status']>,
    ) => {
      state.status = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    clearHotelInfo: (state) => {
      state.data = null;
      state.status = 'idle';
      state.error = null;
    },
  },
});

export const { setHotelInfo, setFetchStatus, setError, clearHotelInfo } =
  hotelInfoSlice.actions;

export default hotelInfoSlice.reducer;
